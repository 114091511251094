import React from 'react';
import withAuthorization from './withAuthorization.jsx';
import { Tab, Tabs } from 'react-bootstrap'
import { Button } from 'reactstrap'
import * as firebase from 'firebase';
import { notify } from 'react-notify-toast';

import AppStatus from './AppStatus.jsx';
import StoreListing from './StoreListing.jsx';
import AddAppId from './AddAppId.jsx';
import AppInfos from './AppInfos.jsx';
import UniqueProductionTask from './UniqueProductionTask/UProductionComponent';
import StatusLinkTab from './StatusAndLinksTab/StatusLink'

import './App.css';
import Notifications from 'react-notify-toast';
import Autosuggest from 'react-autosuggest';
import theme from './Suggestion_theme.css';
import axios from 'axios';
import { fbind } from 'q';

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

class HomePage extends React.Component {
	constructor() {
		super();

		this.state = {
			title: '',
			data: [],
			value: '',
			suggestions: [],
			triggerText:"",
			lastAppId: "",
			tempAppid: ""
		}
		this.getValue = this.getValue.bind(this)
		this.handleCallbackChange = this.handleCallbackChange.bind(this);
		this.applyAppid = this.applyAppid.bind(this);
		this.checkAppidIsValid = this.checkAppidIsValid.bind(this);

	}

	getAppID = () => { // dummy function this can be used as callback once everthing is finally tested
		return this.state.title 
	}

	handleCallbackChange(){

		this.setState({
			triggerText: "true"
		});
	}

	onChange = (event, { newValue }) => {
		this.setState({
			title:'',
			value: newValue
		});
	};

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: this.getSuggestions(value)
		});
	};

	suggested_values = [];

	getinfo = (inputQuery) => {
		this.api = "https://vajro.herokuapp.com/appinfo?q=" + inputQuery;

		axios.get(this.api).then(({ data }) => {
			this.suggestion_array = [];
			if(data.apps){
				if(data.apps.length>0){
					for(var i=0; i<data.apps.length; i++){
						// if(data.apps[i].platform.toLowerCase() === 'shopify'){
						this.suggestion_json = {
							appid : data.apps[i].appid,
							name : data.apps[i].name + " - " + data.apps[i].appid
						}
						this.suggestion_array.push(this.suggestion_json);
						// }
					}
					this.suggested_values = this.suggestion_array;
				}
			}        
		});
	}
  

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

  
	getSuggestions = value => {

		this.inputValue = value.trim().toLowerCase();

		this.inputLength = this.inputValue.length;

		if(this.inputLength>0){
			this.getinfo(this.inputValue)
		}

		return this.inputLength === 0 ? [] : this.suggested_values.filter(lang =>
			lang.name.toLowerCase().slice(0, this.inputLength) === this.inputValue
		);
	};

	applyAppid() {
		return(
			<div className = "applyAppidContainer">
				<label className = "col-md-2">Appid : </label>
				<input  className="appidInputBox row"  value = {this.state.tempAppid} onChange={event => this.setState(byPropKey('tempAppid', event.target.value))} />
				<button className = "applyAppidButton row" onClick= {this.checkAppidIsValid} > Apply Appid </button>     
			</div>
		);
	}


	checkAppidIsValid(){

		let myColor = {
			background: '#2196F3',
			text: "#FFFFFF"
		};
		const self = this;
		var appid = self.state.tempAppid;
		console.log("Appid - " + appid); 

		firebase.database().ref("/accessKeys/" + appid).once('value').then(function(snapshot) {
			if (snapshot.val()) {
				self.setState({
					"isValidAppid": true,
					"title":appid
				});
				notify.show("Valid Appid", "custom", 1000, myColor)
		
			} else{
				self.setState({
					"isValidAppid": false,
					"title":""
				});
				notify.show("Invalid Appid", "error", 1000, myColor)

			}
		});
	}
	onSuggestionSelected = event => {
		var target = '' + event.target.textContent;

		if(!target){
			target = event.target.value;
		}

		if(target){
			for(var i=0; i<this.suggested_values.length; i++){
				if(target.indexOf(this.suggested_values[i].appid) > -1 ){
					this.setState({
						title: this.suggested_values[i].appid
					});
					break;
				}
			}
		} 
	}

	getSuggestionValue = suggestion => suggestion.name;


	renderSuggestion = suggestion => (
		<div>
			{suggestion.name}
		</div>
	);


	   
  render() {

    const { value, suggestions } = this.state;
		const inputProps = {
			placeholder: 'Store Name',
			value,
			onChange: this.onChange,
		};

    return (
		<div className = "container-fluid">
			<Notifications />
			{this.applyAppid()}
			<div className = "row" style = {{"display":"flex"}}>
				<div className = "suggestion-box" style = {{"marginBottom":"34px"}}>
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
						getSuggestionValue={this.getSuggestionValue}
						renderSuggestion={this.renderSuggestion}
						onSuggestionsClearRequested={this.onSuggestionsClearRequested}
						onSuggestionSelected = {this.onSuggestionSelected}
						inputProps={inputProps}
					/>
				</div>
				<Button className = "refresh_icon_button button-hover" color="secondary">
					<i class="fas fa-search"></i>
				</Button>
			</div>        
			
			<Tabs defaultActiveKey={6} id="uncontrolled-tab-example">
				
				<Tab eventKey={6} title="Status & Links" onSelect={this.autoUpdateFirstTab(6)} >
					<StatusLinkTab data = {this.state.title} getApp = {this.getAppID} />
				</Tab>
				<Tab eventKey={4} title="App Info" >
					<AppInfos data = {this.state.title} getApp = {this.getAppID} />
				</Tab>
				
				<Tab eventKey={1} title="App Status">
					<AppStatus data = {this.state.title} getApp = {this.handleCallbackChange} />
				</Tab>

				<Tab eventKey={2} title="Store Listing" >
					<StoreListing data = {this.state.title} getApp = {this.getAppID} />
				</Tab>

				{/* <Tab eventKey={3} title="Add App ID" >
					<AddAppId data = {this.state.title} getApp = {this.getAppID} />
				</Tab> */}
				{/* <Tab eventKey={5} title="Production Task" >
					<UniqueProductionTask data = {this.state.title} getApp = {this.getAppID} />
				</Tab> */}
				
			</Tabs>
		</div>
    );
	}

	
	autoUpdateFirstTab(index){	// this function is written to auto update the first component

		if(JSON.stringify (this.state.title) !== JSON.stringify(this.state.lastAppId)){
			// set timeout function is written to avoid collision from other component is rendering
			setTimeout(() => {	
				this.setState({
					lastAppId : this.state.title,
					triggerText : "value triggered"
				});
			}, 2000);
			
		}	
	}

	handleChange(event) {
		this.setState({
			title: event.target.value
		});
	}

	getValue() {
		this.forceUpdate()
	}
}


const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(HomePage);