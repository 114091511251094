import React from 'react';
import * as firebase from 'firebase';
import KRForm from './form';
import Notifications from 'react-notify-toast';

class CreateKRUser extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="create_kr_container">
                <Notifications />
                <KRForm />
            </div>
        );
    }
}

export default CreateKRUser;