import * as firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDL0FZvO5y2Z9xlJZGPd5fh1L53H-6lFww",
    authDomain: "project-950777300636680597.firebaseapp.com",
    databaseURL: "https://project-950777300636680597.firebaseio.com",
    projectId: "project-950777300636680597"
  };

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth =  firebase.auth();
const db = firebase.database();

export {
  db,
  auth,
};