import React from 'react';
import * as firebase from 'firebase';
import Facebook_signin_button from './facebook_signin.jsx';
import Facebook_post_message from './facebook_post';
import FacebookLive from './Facebook_live.jsx';

class Facebook_functions extends React.Component{

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className = "FacebookContainer">
                <h1>Testing Facebook Functionality</h1>
                <Facebook_signin_button />
                <Facebook_post_message />
                <FacebookLive />
            </div>
        )
    }

}

export default Facebook_functions
