import React from 'react';
import * as firebase from 'firebase';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import { Button, Form, FormGroup, Label, Input} from 'reactstrap';
import './App.css';

class AddAppId extends React.Component {

  constructor(props) {
    super(props);

    this.onBtnClick = this.onBtnClick.bind(this);
    this.handleChangeAppId = this.handleChangeAppId.bind(this);
    this.fetchUser = this.fetchUser.bind(this);

    this.state = {
    	appid: "",
    	email: "",
    	currentUser: "" ,
    	uid: null,
    	api: "",
    };

  };

   componentWillMount(){
	if(firebase.auth().currentUser){
		this.setState({
			uid: firebase.auth().currentUser.uid
		});
	}
    }

  fetchUser() {
    axios.get('')
      .then( (response) => {
        // console.log("response", response);
        this.setState({
          fetchUser: response.data
        });
        // console.log("fetchUser", this.state.fetchUser);
      })
      .catch( (error) => {
        console.log(error);
      });  
  }

  handleChangeAppId(event) {
    this.setState({
      appid: event.target.value
    })
  }

  onBtnClick(){

	  this.api = "https://vajrostaging.herokuapp.com/map_admin_uid?appid=" + this.state.appid + "&uid=" + this.state.uid;
    // this.api = "http://localhost:5000/map_admin_uid?appid=" + this.state.appid + "&uid=" + this.state.uid;

     let myColor = {
      background: '#0bdee5',
      text: "#FFFFFF"
    };

    var config = {
       headers: {'Authorization': 'Basic a2FzaTpyYWphbg==','Access-Control-Allow-Headers':'Accept'}
    };
    axios.get(this.api,config).then(response => {
           
      var body = response.data;
      if(body.status === "success"){
        notify.show("App Id Updated Successfully", "success", 3000, myColor);
      }else{
        notify.show("Failed to Update App Id", "error", 3000, myColor)
      }
    })
    .catch((error) => {
      notify.show("Failed to Update App Id", "error", 3000, myColor)  
      console.log('error 3 ' + error);
    });
  }





  render() {

    return (
    	<Form >
	        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
	          <Label className="mr-sm-2 " ><h4 style = {{padding : "11px" }}>Enter the App Id to map for Shopify </h4></Label>
	          <Input className = "add_app_id_textbox" type="number" name="appid" placeholder="Enter the App Id to Map" onChange={this.handleChangeAppId.bind(this)}/>
	        </FormGroup>
	        <Button className = "refresh_button button-hover" onClick={this.onBtnClick} >Submit</Button>
	    </Form>
    	
    );

  }
}

export default AddAppId