import React from 'react';
import { Button, Card, CardBody, CardImage, CardTitle, CardText, Col } from 'mdbreact';
import './task.css';
import { Badge } from 'reactstrap';
import { Dropdown,MenuItem,Glyphicon,DropdownButton,ButtonToolbar } from 'react-bootstrap';
import * as firebase from 'firebase';
import { notify } from 'react-notify-toast';
import SweetAlert from "react-bootstrap-sweetalert/index";
import MappleToolTip from 'reactjs-mappletooltip';

class TaskCard extends React.Component {
    constructor(props){
        super(props);

        this.updateStatusInDb = this.updateStatusInDb.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleStatusChangeComponent = this.handleStatusChangeComponent.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
        this.handleDeleteTaskOnConfirm = this.handleDeleteTaskOnConfirm.bind(this);

        this.state = {
            task : [],
            onHold_task : [],
            pending_task : [],
            live_task : [],
            alert: "",
            uploaded_task : [],
            submitted_task: []
        }
    }

    componentDidUpdate(props){
        if(this.props.onHold_task){
            if(JSON.stringify (this.props.onHold_task) !== JSON.stringify(this.state.onHold_task)){
                this.setState({
                        onHold_task : this.props.onHold_task
                });
            } 
		} else if(this.props.pending_task){
            if(JSON.stringify (this.props.pending_task) !== JSON.stringify(this.state.pending_task)){
                this.setState({
                    pending_task : this.props.pending_task
                });
            }    
        } else if(this.props.live_task){
            if(JSON.stringify (this.props.live_task) !== JSON.stringify(this.state.live_task)){
                this.setState({
                    live_task : this.props.live_task
                });
            }  
        } else if(this.props.uploaded_task){
            if(JSON.stringify (this.props.uploaded_task) !== JSON.stringify(this.state.uploaded_task)){
                this.setState({
                    uploaded_task : this.props.uploaded_task
                });
            }     
        } else if(this.props.submitted_task){
            if(JSON.stringify (this.props.submitted_task) !== JSON.stringify(this.state.submitted_task)){
                this.setState({
                    submitted_task : this.props.submitted_task
                });
            }     
        }
    }

    handleStatusChangeComponent(update_array){// this function triggers the callback which will get updated value from database
        this.props.onChangeTaskArray(update_array);
    }
    
    
    handleDeleteTask(target,index,current_status){
        this.popupThisGoal(target, index, current_status);
    }


    popupThisGoal(target, index, current_status) {

        const getAlert = () => (
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={ () => {
                    this.handleDeleteTaskOnConfirm(target,index,current_status);
                    this.hideAlert();
                }
                }
                onCancel={this.hideAlert}
            >
            </SweetAlert>
        );
        
        this.setState({
            alert: getAlert()
        });
		
    }
    
    hideAlert() {
		this.setState({
			alert: null
		});
	}

    handleDeleteTaskOnConfirm(target,index,current_status){
        if(current_status === "onhold"){
            var cloneArray = this.state.onHold_task;
            this.deleteTaskInDB(cloneArray[index].id);
            cloneArray = cloneArray.splice(index,1);
            this.setState({
                onHold_task : cloneArray
            });
        } else if(current_status === "live"){
            var cloneArray = this.state.live_task;
            this.deleteTaskInDB(cloneArray[index].id);
            cloneArray = cloneArray.splice(index,1);
            this.setState({
                live_task : cloneArray
            });
        } else if(current_status === "uploaded"){
            var cloneArray = this.state.uploaded_task;
            this.deleteTaskInDB(cloneArray[index].id);
            cloneArray = cloneArray.splice(index,1);
            this.setState({
                uploaded_task : cloneArray
            });
        } else if(current_status === "pending"){
            var cloneArray = this.state.pending_task;
            this.deleteTaskInDB(cloneArray[index].id);
            cloneArray = cloneArray.splice(index,1);
            this.setState({
                pending_task : cloneArray
            });
        } else if(current_status === "submitted"){
            var cloneArray = this.state.submitted_task;
            this.deleteTaskInDB(cloneArray[index].id);
            cloneArray = cloneArray.splice(index,1);
            this.setState({
                submitted_task : cloneArray
            });
        }
    }

    deleteTaskInDB(task_id){
        let db_ref = firebase.database().ref('/tasks/production/'+task_id);
        db_ref.remove();
        notify.show("Task Deleted SuccessFully", "error", 2000);
        this.handleStatusChangeComponent("uploaded");

    }

    handleStatusChange(target,index,task_string){

        var current_status = "onhold";
        if(target === "onhold"){
            current_status = "onhold";
        } else if(target == "live"){
            current_status = "live";
        } else if(target == "pending"){
            current_status = "pending";
        } else if(target == "submitted"){
            current_status = "submitted";
        } else if(target == "uploaded"){
            current_status = "uploaded";
        } else if(target == "archive"){
            current_status = "archive";
        }

       if(task_string === "onhold"){
            var cloneArray = this.state.onHold_task;
            cloneArray[index].status = current_status;
            this.setState({
                onHold_task : cloneArray
            });
            
            // there are two components which contains onhold task and pending task.
            // if any task in onhold task array is updated then value should be changed in the onhold task array, in order to indicate that onhold or pending string is passed.
            this.updateStatusInDb(index,"onhold");
            this.props.onChangeTaskArray(cloneArray);
            this.handleStatusChangeComponent("onhold");
       } else if(task_string === "pending"){
            var cloneArray = this.state.pending_task;
            cloneArray[index].status = current_status;
            this.setState({
                pending_task : cloneArray
            });
            this.updateStatusInDb(index,"pending");
            this.handleStatusChangeComponent("pending");
            this.props.onChangeTaskArray(cloneArray);
       } else if(task_string === "live"){
            var cloneArray = this.state.live_task;
            cloneArray[index].status = current_status;
            this.setState({
                live_task : cloneArray
            });
            this.updateStatusInDb(index,"live");
            this.handleStatusChangeComponent("live");
            this.props.onChangeTaskArray(cloneArray);
        } else if(task_string === "uploaded"){
            var cloneArray = this.state.uploaded_task;
            cloneArray[index].status = current_status;
            this.setState({
                uploaded_task : cloneArray
            });
            this.updateStatusInDb(index,"uploaded");
            this.handleStatusChangeComponent("uploaded");
            this.props.onChangeTaskArray(cloneArray);
        }else if(task_string === "submitted"){
            var cloneArray = this.state.submitted_task;
            cloneArray[index].status = current_status;
            this.setState({
                submitted_task : cloneArray
            });
            this.updateStatusInDb(index,"submitted");
            this.handleStatusChangeComponent("submitted");
            this.props.onChangeTaskArray(cloneArray);
        }
    }

    updateStatusInDb(index,prev_task_status){

        var task_array = [];
        if(prev_task_status === "onhold"){
           task_array = this.state.onHold_task;
        } else if (prev_task_status === "pending"){
            task_array = this.state.pending_task;
        } else if (prev_task_status === "live"){
            task_array = this.state.live_task;
        } else if (prev_task_status === "uploaded"){
            task_array = this.state.uploaded_task;
        } else if (prev_task_status === "submitted"){
            task_array = this.state.submitted_task;
        }

        var task_id = task_array[index].id;
        var final_json = {};
        var final_json = task_array[index];
        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);
        if(final_json.status === "live"){
            final_json.completed_at = timestamp;
        }
        final_json.updated_at = timestamp;
        let db = firebase.database().ref('/tasks/production/'+task_id);
        db.set(final_json);
        this.updateHeapAnalyticsOnStatusChange(final_json);
        
    }

    updateHeapAnalyticsOnStatusChange(task_json) {
        window.heap.track(task_json.status, {"reason": task_json.reason, "appid": task_json.appid,"platform":task_json.platform,"task_id":task_json.id,"app_name":task_json.app_name});
    }

    render() {
        var production_task = this.state.onHold_task;
        const self = this;
        var onHold_task_list = production_task.map(function(individual,index){
            return (
                <Col key={index.toString()} >
                    <Card >
                        <CardBody className = "pending_task_card_css">
                            <div>
                                <button className = "task_delete_button div_delete_button" onClick = {e => self.handleDeleteTask(e,index,"onhold") }><i className="far fa-times-circle"></i></button>
                            </div>
                            <CardTitle> {individual.app_name} -  {individual.appid}
                            </CardTitle>   
                                <p className="para-card-reason">{individual.reason}</p>
                                <div className = "react_dropdown"> 
                                    <ButtonToolbar>
                                        <Dropdown id="dropdown-custom-1" >
                                        <Dropdown.Toggle>
                                            {individual.status}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="super-colors" onSelect = {e =>self.handleStatusChange(e,index,"onhold")}>
                                        <MenuItem eventKey="pending">Pending</MenuItem>
                                        <MenuItem eventKey="submitted">Submitted</MenuItem>
                                        <MenuItem divider />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonToolbar>
                                </div>  
                                <Badge className = "platform_badge" color="secondary">{individual.platform}</Badge>
                                {individual.since_updated.includes("days")?(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge"><i className="far fa-clock"></i> {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                ):(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge_change_color"><i className="far fa-clock"></i> {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                )}

                                {individual.intercom ?(
                                    <button onClick= {() => window.open(individual.intercom)} className = "intercom_css"> <img className="intercom_image_width" src="https://static.intercomassets.com/assets/favicon-06ecf67d3fb6bd7bf7de78c76f62edae6a3c4bee48aa2877d7403201f0401e78.png" />
                                    intercom</button>
                                ):(
                                    <div></div>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            );
        });


        var production_task = this.state.pending_task;
        var pending_task_list = production_task.map(function(individual,index){
            return (
                <Col key={index.toString()} >
                    <Card >
                        <CardBody className = "pending_task_card_css">
                            <div>
                                <button className = "task_delete_button div_delete_button" onClick = {e => self.handleDeleteTask(e,index,"pending") }><i className="far fa-times-circle"></i></button>
                            </div>
                            <CardTitle> {individual.app_name} -  {individual.appid}
                            </CardTitle>   
                                <p className="para-card-reason">{individual.reason}</p>
                                <div className = "react_dropdown"> 
                                    <ButtonToolbar>
                                        <Dropdown id="dropdown-custom-1" >
                                        <Dropdown.Toggle>
                                            {individual.status}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="super-colors" onSelect = {e =>self.handleStatusChange(e,index,"pending")}>
                                        <MenuItem eventKey="uploaded">Uploaded</MenuItem>
                                        <MenuItem eventKey="onhold" >Onhold</MenuItem>
                                        <MenuItem divider />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonToolbar>
                                </div>                    
                                <Badge className = "platform_badge" color="secondary">{individual.platform}</Badge>
                                {individual.since_updated.includes("days")?(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge"><i className="far fa-clock"></i> Created {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                             Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                ):(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge_change_color"><i className="far fa-clock"></i> Created {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                )}
                                {individual.intercom ?(
                                    <button onClick= {() => window.open(individual.intercom)} className = "intercom_css"> <img className="intercom_image_width" src="https://static.intercomassets.com/assets/favicon-06ecf67d3fb6bd7bf7de78c76f62edae6a3c4bee48aa2877d7403201f0401e78.png" /></button>
                                ):(
                                    <div></div>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            );
        });

        var production_task = this.state.live_task;
        var live_task_list = production_task.map(function(individual,index){
            return (
                <Col key={index.toString()} >
                    <Card >
                        <CardBody className = "pending_task_card_css">
                            <div>
                                <button className = "task_delete_button div_delete_button" onClick = {e => self.handleDeleteTask(e,index,"live") }><i className="far fa-times-circle"></i></button>
                            </div>
                            <CardTitle> {individual.app_name} -  {individual.appid}
                            </CardTitle>   
                                <p className="para-card-reason">{individual.reason}</p>
                                <div className = "react_dropdown"> 
                                    <ButtonToolbar>
                                        <Dropdown id="dropdown-custom-1" >
                                        <Dropdown.Toggle>
                                            {individual.status}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="super-colors" onSelect = {e =>self.handleStatusChange(e,index,"live")}>
                                        <MenuItem eventKey="archive" >Archive</MenuItem>
                                        <MenuItem divider />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonToolbar>
                                </div>                    
                                <Badge className = "platform_badge" color="secondary">{individual.platform}</Badge>
                                {individual.since_updated.includes("days")?(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge"><i className="far fa-clock"></i> Live {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created  {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                ):(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge_change_color"><i className="far fa-clock"></i> Live {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                )}
                                {individual.intercom ?(
                                    <button onClick= {() => window.open(individual.intercom)} className = "intercom_css"> <img className="intercom_image_width" src="https://static.intercomassets.com/assets/favicon-06ecf67d3fb6bd7bf7de78c76f62edae6a3c4bee48aa2877d7403201f0401e78.png" /></button>                                    ):(
                                    <div></div>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            );
        });

        var production_task = this.state.uploaded_task;
        var uploaded_task_list = production_task.map(function(individual,index){
            return (
                <Col key={index.toString()} >
                    <Card >
                        <CardBody className = "pending_task_card_css">
                            <div>
                                <button className = "task_delete_button div_delete_button" onClick = {e => self.handleDeleteTask(e,index,"uploaded") }><i className="far fa-times-circle"></i></button>
                            </div>
                            <CardTitle> {individual.app_name} -  {individual.appid}
                            </CardTitle>   
                                <p className="para-card-reason">{individual.reason}</p>
                                <div className = "react_dropdown"> 
                                    <ButtonToolbar>
                                        <Dropdown id="dropdown-custom-1" >
                                        <Dropdown.Toggle>
                                            {individual.status}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="super-colors" onSelect = {e =>self.handleStatusChange(e,index,"uploaded")}>
                                        <MenuItem eventKey="submitted">Submitted</MenuItem>
                                       <MenuItem divider />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonToolbar>
                                </div>     
                                <Badge className = "platform_badge" color="secondary">{individual.platform}</Badge>
                                {individual.since_updated.includes("days")?(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge"><i className="far fa-clock"></i> Uploaded {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                ):(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge_change_color"><i className="far fa-clock"></i> Uploaded {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                )}
                                {individual.intercom ?(
                                    <button onClick= {() => window.open(individual.intercom)} className = "intercom_css"> <img className="intercom_image_width" src="https://static.intercomassets.com/assets/favicon-06ecf67d3fb6bd7bf7de78c76f62edae6a3c4bee48aa2877d7403201f0401e78.png" /></button>                                    ):(
                                    <div></div>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            );
        });


        var production_task = this.state.submitted_task;
        var submitted_task_list = production_task.map(function(individual,index){
            return (
                <Col key={index.toString()} >
                    <Card >
                        <CardBody className = "pending_task_card_css">
                            <div>
                                <button className = "task_delete_button div_delete_button" onClick = {e => self.handleDeleteTask(e,index,"submitted") }><i className="far fa-times-circle"></i></button>
                            </div>
                            <CardTitle> {individual.app_name} -  {individual.appid}
                            </CardTitle>   
                                <p className="para-card-reason">{individual.reason}</p>
                                <div className = "react_dropdown"> 
                                    <ButtonToolbar>
                                        <Dropdown id="dropdown-custom-1" >
                                        <Dropdown.Toggle>
                                            {individual.status}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="super-colors" onSelect = {e =>self.handleStatusChange(e,index,"submitted")}>
                                        <MenuItem eventKey="live">Live</MenuItem>
                                        <MenuItem eventKey="onhold" >Onhold</MenuItem>
                                        <MenuItem divider />
                                        </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonToolbar>
                                </div>  
                                <Badge className = "platform_badge" color="secondary">{individual.platform}</Badge>
                                {individual.since_updated.includes("days")?(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge"><i className="far fa-clock"></i> Submitted {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                ):(
                                    <MappleToolTip>
                                        <div>
                                            <Badge className = "since_created_time_badge_change_color"><i className="far fa-clock"></i> Submitted {individual.since_updated}</Badge>                  
                                        </div>
                                        <div>
                                            Created {individual.since_created}
                                        </div>
                                    </MappleToolTip>
                                )}
                                {individual.intercom ?(
                                    <button onClick= {() => window.open(individual.intercom)} className = "intercom_css"> <img className="intercom_image_width" src="https://static.intercomassets.com/assets/favicon-06ecf67d3fb6bd7bf7de78c76f62edae6a3c4bee48aa2877d7403201f0401e78.png" /></button>                                    ):(
                                    <div></div>
                                )}
                        </CardBody>
                    </Card>
                </Col>
            );
        });


        return(
            <div>
                {this.state.alert}
                {onHold_task_list}
                {pending_task_list}
                {live_task_list}
                {uploaded_task_list}
                {submitted_task_list}
            </div>
        )
            
        
    }
}

export default TaskCard;