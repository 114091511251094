import React from 'react';

import AuthUserContext from './AuthUserContext.jsx';
import { PasswordForgetForm } from './PasswordForget.jsx';
import PasswordChangeForm from './PasswordChange.jsx';
import withAuthorization from './withAuthorization.jsx';
import './App.css';

const AccountPage = () => <AuthUserContext.Consumer>
    {authUser => <div className="container-fluid">
       <div> 
        <h1> Account: {authUser.email}</h1>
       </div>
        <PasswordForgetForm />
        <div>(OR)</div>
        <PasswordChangeForm />
      </div>
  }
  </AuthUserContext.Consumer>

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AccountPage);