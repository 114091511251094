import React from 'react';
import * as firebase from 'firebase';
import './facebook.css';
/*global FB*/

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

class Facebook_signin_button extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className = "FacebookSigninContainer">
                <h2>Facebook Signin Container</h2>
                <div className="fb-login-button" scope="pages_manage_instant_articles,pages_manage_cta,public_profile,manage_pages,pages_show_list,publish_pages,read_page_mailboxes" data-size="medium" data-button-type="continue_with" data-auto-logout-link="false" data-use-continue-as="false"></div>            
            </div>
        )
    }
}

export default Facebook_signin_button;