import React from 'react';
import { Button, Card, CardBody, CardImage, CardTitle, CardText, Col } from 'mdbreact';
import '../ProductionTask/task.css';
import { Badge } from 'reactstrap';
import { Dropdown,MenuItem,Glyphicon,DropdownButton,ButtonToolbar } from 'react-bootstrap';
import * as firebase from 'firebase';
import { notify } from 'react-notify-toast';


class UTaskCard extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            production_tasks : []
        }
    }


    componentDidUpdate(props){
        if(this.props.production_tasks){
            if(JSON.stringify (this.props.production_tasks) !== JSON.stringify(this.state.production_tasks)){
                this.setState({
                        production_tasks : this.props.production_tasks
                });
            }
        } 
    }

    render(){
        var production_task = this.state.production_tasks;
        const self = this;
        var production_task_card = production_task.map(function(invidual_task,index){
            return (
                <Col key={index.toString()} className="col-md-4" >
                    <Card >
                        <CardBody className = "pending_task_card_css">
                            <CardTitle> {invidual_task.app_name} -  {invidual_task.appid}
                            </CardTitle>   
                                <p className="para-card-reason">{invidual_task.reason}</p>
                                <div>{invidual_task.status}</div>                   
                                <Badge className = "platform_badge" color="secondary">{invidual_task.platform}</Badge>
                                <Badge className = "since_created_time_badge"><i className="far fa-clock"></i> {invidual_task.date}</Badge>
                        </CardBody>
                    </Card>
                </Col>
            );
        });

        return (
            <div>
                {production_task_card}
            </div>
        )
    }
}

export default UTaskCard;