import React from 'react';
import * as firebase from 'firebase';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { notify } from 'react-notify-toast';
import { Button } from 'reactstrap'
import axios from 'axios';


class AppInfos extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeAppUrl = this.onChangeAppUrl.bind(this);
        this.displayAppLinks = this.displayAppLinks.bind(this);
        this.displayPackageName = this.displayPackageName.bind(this);
        this.onChangeAppName = this.onChangeAppName.bind(this);
        this.loadAppStatus = this.loadAppStatus.bind(this);
        this.loadAppStatus.bind(props.database)
        this.handleChangeAndroidDev = this.handleChangeAndroidDev.bind(this);
        this.onChangeAndroidDevAccount = this.onChangeAndroidDevAccount.bind(this);
		this.handleChangeiOSDev = this.handleChangeiOSDev.bind(this);
        this.onChangeiOSDevAccount = this.onChangeiOSDevAccount.bind(this);


        this.state = {
            appid: "",
			androidLink: "",
			iosLink: "",
			android_package: "",
			ios_package: "",
			ios_appid: "",
			storeName:"",
			storeUrl:"",
			showClass:"hide",
			appStatusFlag: false,
			android_team_id: "",
			android_team_id_url: "",
			android_team_name: "",
			android_playstore_app_id: "" ,
			ios_dev_emailid: "",
			ios_dev_password: "",
			ios_dev_team_id: "",
			ios_dev_team_name: ""
        };
    }

    componentWillReceiveProps(){
		if(this.props.data.length > 3){
			this.loadAppStatus(this.props.data);
		}
    };
    
    loadAppStatus(appid) {

		let myColor = {
			background: '#2196F3',
			text: "#FFFFFF"
		};
		this.setState({
			appid: appid
		});
		notify.show("Loading ....", "custom", 1500, myColor)
		const self = this;
		firebase.database().ref("/apps/" + appid).once('value').then(function(snapshot) {
			if (snapshot.val()) {
			firebase.database().ref("/nusers/" + snapshot.val().uid).once('value').then(function(u_snapshot) {
				if (u_snapshot.val()) {
					if(u_snapshot.val().appstatus) {

						self.setState({
							appStatusFlag: true,
							items: u_snapshot.val().appstatus,
							androidLink: u_snapshot.val().apps.android.url,
							iosLink: u_snapshot.val().apps.ios.url
						});
					} else {
						self.setState({
							appStatusFlag: false,
						});
						notify.show("App Status missing ", "error", 3000, myColor)
					}
					firebase.database().ref("/apps/" + appid + "/").once('value').then(function(package_snapshot) {
						self.setState({
							android_package: package_snapshot.val().storedata.android_package_name,
							ios_package: package_snapshot.val().storedata.ios_package_name,
							ios_appid: package_snapshot.val().storedata.ios_appid,
							storeName:package_snapshot.val().name,
							storeUrl:package_snapshot.val().storeUrl
						});
					});

					firebase.database().ref("/extra/preview/apps/" + appid ).once('value').then(function(devAccountDetails){
						if(devAccountDetails.val()){
							if(devAccountDetails.val().dev_account){
								if(devAccountDetails.val().dev_account.android){
									self.setState({
										android_playstore_app_id: devAccountDetails.val().dev_account.android.playstore_app_id,
										android_team_id_url: "https://play.google.com/apps/publish/?account=" + devAccountDetails.val().dev_account.android.team_id,
										android_team_id: devAccountDetails.val().dev_account.android.team_id,
										android_team_name: devAccountDetails.val().dev_account.android.team_name
									});
								} else{
									self.setState({
										android_playstore_app_id: "",
										android_team_id: "",
										android_team_name: "",
										android_team_id_url: ""
									});
								}

								if(devAccountDetails.val().dev_account.ios){
									self.setState({
										ios_dev_emailid: devAccountDetails.val().dev_account.ios.emailid,
										ios_dev_password: devAccountDetails.val().dev_account.ios.password,
										ios_dev_team_id: devAccountDetails.val().dev_account.ios.team_id,
										ios_dev_team_name: devAccountDetails.val().dev_account.ios.team_name
									});
								} else{
									self.setState({
										ios_dev_emailid: "",
										ios_dev_password: "",
										ios_dev_team_id: "",
										ios_dev_team_name: ""
									});
								}
							} else{
								self.setState({
									android_playstore_app_id: "",
									android_team_id: "",
									android_team_name: "",
									android_team_id_url: "",
									ios_dev_emailid: "",
									ios_dev_password: "",
									ios_dev_team_id: "",
									ios_dev_team_name: ""
								});
							}
							
						} else{
							self.setState({
								android_playstore_app_id: "",
								android_team_id: "",
								android_team_name: "",
								android_team_id_url: "",
								ios_dev_emailid: "",
								ios_dev_password: "",
								ios_dev_team_id: "",
								ios_dev_team_name: ""
							});
						}
					})
					self.state.showClass = "";
					notify.show("Loaded Successfully", "custom", 3000, myColor)
					// console.log(self.state.items);
				} else {
					console.log("No App Status for this ID");
				}
			}).catch(function(error) {
				console.log(error);
				notify.show("Permission Denied", "error", 3000, myColor)
			})
			} else {
				notify.show("Invalid Appid", "error", 3000, myColor)
				console.log("Invalid Appid");
			}
		}).catch(function(error) {
			console.log(error);
			notify.show("Permission Denied", "error", 3000, myColor)
		});
	}
	
	showNotification(){
		
		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};
		notify.show("Copied Successfully", "success", 500, myColor);
	}

    displayAppLinks() {
		const self = this;

		return (
			<div className ="displayAppLinksBox" >
                <label className = "labelAppLink col-md-3">Android App Link  </label><input className = "androidLinks button-default" type = "text" value = {this.state.androidLink} placeholder = "Enter the Android URL" onChange={this.handleChangeAppLinkAndroid.bind(this)} />
                <CopyToClipboard text={this.state.androidLink} >
                     <Button className="copy_icon_button button-hover" onClick = {this.showNotification} ><i className="far fa-copy"></i> </Button>
                </CopyToClipboard>
                <button className = "external_link_button" onClick= {() => window.open(self.state.androidLink)}><i className="fas fa-external-link-square-alt"></i></button>
                <br></br>
                
                <label className = "labelAppLink col-md-3">iOS App Link  </label><input className = "iosLinks button-default" type = "text" value = {this.state.iosLink} placeholder = "Enter the IOS URL" onChange={this.handleChangeAppLinkIos.bind(this)}/>
                <CopyToClipboard text={this.state.iosLink} >
                    <Button className="copy_icon_button button-hover" onClick = {this.showNotification}><i className="far fa-copy"></i> </Button>
                </CopyToClipboard>
                <button className = "external_link_button" onClick= {() => window.open(self.state.iosLink)}><i className="fas fa-external-link-square-alt"></i></button>
                <br />
                <div className="col-md-offset-3 row">
                    <Button className = "save_button button-hover" color="secondary" onClick = {this.onChangeAppUrl} > Save </Button> 
                </div>
		    </div>
		);
    };
    
    handleChangeAppLinkAndroid(event) {
		this.setState({
			androidLink: event.target.value
		});
    };
    
    handleChangeAppLinkIos(event) {
		this.setState({
			iosLink: event.target.value
		});
	};
    
    onChangeAppUrl() {
		const self = this;
		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};

		notify.show("Updated Successfully", "success", 3000, myColor);
		firebase.database().ref("/apps/" + self.state.appid).once('value').then(function(snapshot) {
			if (snapshot.val()) {
				let db = firebase.database().ref("/nusers/" + snapshot.val().uid + "/apps/");
				db.update({
				"android": {
					"url": self.state.androidLink
				},
				"ios": {
					"url": self.state.iosLink
				}
				});
			} else {
				console.log("Invalid Appid");
			}
		});  

		this.triggerZohoEvent(self.state.appid,"APP_INFO")
    };
    
    displayPackageName() {
		return (
            <div className ="displayAppLinksBox" >
                <label className = "labelAppLink col-md-3">Android Package Name  </label>
                <input className = "androidLinks button-default" type = "text" value = {this.state.android_package} placeholder = "Enter the Android Package Name" onChange={this.handleChangePackageAndroid.bind(this)} />
                <br></br>
                
                <label className = "labelAppLink  col-md-3">iOS Package Name  </label>
                <input className = "iosLinks button-default" type = "text" value = {this.state.ios_package} placeholder = "Enter the iOS Package Name" onChange={this.handleChangePackageIos.bind(this)} />
                <br />
                
                <label className = "labelAppLink  col-md-3">iOS App Id  </label>
                <input className = "iosLinks button-default" type = "text" value = {this.state.ios_appid} placeholder = "Enter the iOS App Id" onChange={this.handleChangeAppIdIos.bind(this)} />
                <br />
                
                <div className="col-md-offset-3 row">
                    <Button className = "save_button button-hover" color="secondary" onClick = {this.onChangeAppName} > Save </Button> 
                </div>
            </div>
		);
	}

    onChangeAppName() {
		
		const self = this;

        let myColor = {
            background: '#50e256',
            text: "#FFFFFF"
        };

        notify.show("Updated Successfully", "success", 3000, myColor);
        let db = firebase.database().ref("/apps/" + this.state.appid + "/storedata/");
        db.update({
            "android_package_name": this.state.android_package,
            "ios_package_name": this.state.ios_package,
			"ios_appid": this.state.ios_appid
		});
	
		firebase.database().ref("/apps/" + self.state.appid).once('value').then(function(snapshot) {
			if (snapshot.val()) {
				let db = firebase.database().ref("/nusers/" + snapshot.val().uid + "/apps/");
				db.update({
				"android": {
					"url": self.state.androidLink
				},
				"ios": {
					"url": self.state.iosLink
				}
				});
			} else {
				console.log("Invalid Appid");
			}
		}); 

		this.triggerZohoEvent(self.state.appid,"APP_INFO")
    };
    
    handleChangePackageAndroid(event) {
		var android_link_url = "https://play.google.com/store/apps/details?id="+event.target.value;
		this.setState({
			android_package: event.target.value,
			androidLink: android_link_url
		});
	}

	handleChangePackageIos(event) {
		this.setState({
			ios_package: event.target.value
		});
	}

	handleChangeAppIdIos(event) {

		var ios_link_url = "https://itunes.apple.com/us/app/id" + event.target.value;
		this.setState({
			ios_appid: event.target.value,
			iosLink: ios_link_url
		});
	}

	triggerZohoEvent(appId,event) {
		const url = "https://cron.vajro.com/api/v1/zoho/start"

		const data = {
			appId,
			event
		}

		const config = {
			headers: {
				"Content-Type": "application/json"
			  }
		}

		axios.post(url,data,config).catch((e)=>{
			notify.show("Zoho event trigger failed","error",2000)
		})
	}
 
    
    displayAndroidDevAccount() {
		const self = this;

		return (
			<div className ="displayAppLinksBox" >
				<label className = "labelAppLink col-md-3">Android Playstore App ID </label><input className = "androidLinks button-default" type = "text" value = {this.state.android_playstore_app_id} placeholder = "Enter the Playstore ID" onChange={this.handleChangeAndroidDev.bind(this,"1")} />
				<br></br>
				<label className = "labelAppLink col-md-3">Android Team Name </label><input className = "androidLinks button-default" type = "text" value = {this.state.android_team_name} placeholder = "Enter the Android Team Name" onChange={this.handleChangeAndroidDev.bind(this,"2")} />
				<br></br>
				<label className = "labelAppLink col-md-3">Android Team ID  </label><input className = "iosLinks button-default" type = "text" value = {this.state.android_team_id} placeholder = "Enter the Android Team ID" onChange={this.handleChangeAndroidDev.bind(this,"3")}/>
				<button className = "external_link_button" onClick= {() => window.open(self.state.android_team_id_url)}><i className="fas fa-external-link-square-alt"></i></button>
				<br />
				<div className="col-md-offset-3 row">
					<Button className = "save_button button-hover" color="secondary" onClick = {this.onChangeAndroidDevAccount} > Save </Button> 
				</div>
			</div>
		);

    };

    handleChangeAndroidDev(id,event){

		if(id === "1"){
			this.setState({
				android_playstore_app_id: event.target.value
			});
		} else if(id === "2"){
			this.setState({
				android_team_name: event.target.value
			});
		} else if(id === "3"){
			this.setState({
				android_team_id_url: "https://play.google.com/apps/publish/?account=" + event.target.value,
				android_team_id: event.target.value
			});
		}
    };
    
    onChangeAndroidDevAccount(){

		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};

		notify.show("Updated Successfully", "success", 3000, myColor);

		let db = firebase.database().ref("extra/preview/apps/" + this.state.appid + "/dev_account/android");
		db.update({
			"playstore_app_id": this.state.android_playstore_app_id,
			"team_id": this.state.android_team_id,
			"team_name": this.state.android_team_name
		});

		this.triggerZohoEvent(this.state.appid,"APP_INFO")
	};
    

    displayiOSDevAccount() {
		return (
			<div className ="displayAppLinksBox" >
				<label className = "labelAppLink col-md-3">iOS Email ID </label><input className = "androidLinks button-default" type = "text" value = {this.state.ios_dev_emailid} placeholder = "Enter the iOS Email ID" onChange={this.handleChangeiOSDev.bind(this,"1")} />
				<br></br>
				<label className = "labelAppLink col-md-3">iOS Password </label><input className = "androidLinks button-default" type = "text" value = {this.state.ios_dev_password} placeholder = "Enter the Password" onChange={this.handleChangeiOSDev.bind(this,"2")} />
				<br></br>
				<label className = "labelAppLink col-md-3">iOS Team Name </label><input className = "androidLinks button-default" type = "text" value = {this.state.ios_dev_team_name} placeholder = "Enter the iOS Team Name" onChange={this.handleChangeiOSDev.bind(this,"3")} />
				<br></br>
				<label className = "labelAppLink col-md-3">iOS Team ID  </label><input className = "iosLinks button-default" type = "text" value = {this.state.ios_dev_team_id} placeholder = "Enter the iOS Team ID" onChange={this.handleChangeiOSDev.bind(this,"4")}/>
				<br />
				<div className="col-md-offset-3 row">
					<Button className = "save_button button-hover" color="secondary" onClick = {this.onChangeiOSDevAccount} > Save </Button> 
				</div>
			</div>
		);

    };

    onChangeiOSDevAccount() {

		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};
		notify.show("Updated Successfully", "success", 3000, myColor);

		let db = firebase.database().ref("extra/preview/apps/" + this.state.appid + "/dev_account/ios");
		db.update({
			"emailid": this.state.ios_dev_emailid,
			"team_id": this.state.ios_dev_team_id,
			"team_name": this.state.ios_dev_team_name,
			"password": this.state.ios_dev_password
		}).catch(function(err){

		});

		this.triggerZohoEvent(this.state.appid,"APP_INFO")
	};
    
	handleChangeiOSDev(id,event) {

		if(id === "1"){
			this.setState({
				ios_dev_emailid: event.target.value
			});
		} else if(id === "2"){
			this.setState({
				ios_dev_password: event.target.value
			});
		} else if(id === "3"){
			this.setState({
				ios_dev_team_name: event.target.value
			});
		} else if(id === "4"){
			this.setState({
				ios_dev_team_id: event.target.value
			})
		}
	}

    render() {
		return( 
            <div className="container-fluid">
                {/* {this.displayAppLinks()}  */}
                {this.displayPackageName()}
                {this.displayAndroidDevAccount()}
                {this.displayiOSDevAccount()}
            </div>
        );
    };
};

export default AppInfos

