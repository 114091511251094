import React from 'react';
import * as firebase from 'firebase';
import './platform_status.css';

class CrashAnalytics extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            appid : "",
            iOS_crash_link: "",
            android_crash_link: ""
        }
    }

    componentDidUpdate(props){
        if(this.props.appid){
            if(JSON.stringify (this.props.appid) !== JSON.stringify(this.state.appid)){
                this.setState({
                        appid : this.props.appid
                });
                this.loadAppValues(this.props.appid)
            }
        } 
    }

    loadAppValues(appid){
        const self = this;

        firebase.database().ref("/apps/" + appid + "/").once('value').then(function(package_snapshot) {
            if(package_snapshot.val().storedata){
                self.setState({
                    android_crash_link: "https://www.fabric.io/vajro/android/apps/" + package_snapshot.val().storedata.android_package_name + "/issues?time=last-seven-days&event_type=crash&subFilter=state&state=open",
                    iOS_crash_link: "https://www.fabric.io/vajro/ios/apps/" + package_snapshot.val().storedata.ios_package_name +"/issues?time=last-seven-days&event_type=crash&subFilter=state&state=open"
                });
            }else{
                self.setState({
                    android_crash_link: "",
                    iOS_crash_link: ""
                }); 
            }
            
        });
    }

    render(){
        const self = this;
        return(
            <div className="row">
                <label className = "col-md-3">Crash Analytics</label>
                <button className = "external_link_button crash-button-custom-css move_app_links" onClick= {() => window.open(self.state.iOS_crash_link)}>iOS</button>
                <button className = "external_link_button crash-button-custom-css" onClick= {() => window.open(self.state.android_crash_link)}>Android</button>
            </div>
        )
    }

}

export default CrashAnalytics