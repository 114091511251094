import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { auth } from '../firebase';
import * as firebase from 'firebase';
import * as routes from '../constants/routes.jsx';
import './SigninCss/signin.css';
import './SigninCss/util.css';
import Tilt from 'react-tilt';


const SignInPage = ({ history }) =>
    <div className = "login_page">
        <div className = "logintab">
            {/* <div className = "login_text"> LOG IN </div> */}
        </div>  
        <SignInForm history = { history }/>
        {/* <PasswordForgetLink / > */}
    </div>

    const byPropKey = (propertyName, value) => () => ({
        [propertyName]: value,
    });

    const INITIAL_STATE = {
        email: '',
        password: '',
        error: null,
    };

    class SignInForm extends Component {
        constructor(props) {
            super(props);
            firebase.auth().onAuthStateChanged(function (user) {
                if (!user) {
                  this.props.history.push('/signin'); // ISSUE: Always redirected to login, even after signup and login
                  return
                } else {
                    window.heap.identify(user.email);
                    window.heap.addUserProperties({'Name': user.displayName,'uid': user.uid});
                    this.props.history.push('/'); // ISSUE: Always redirected to login, even after signup and login
                    return
                }
              }.bind(this))
            this.state = {...INITIAL_STATE };
        }

        onSubmit = (event) => {
            const {
                email,
                password,
            } = this.state;

            const {
                history,
            } = this.props;

            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
               
                auth.doSignInWithEmailAndPassword(email, password)
                .then(() => {
                    this.setState(() => ({...INITIAL_STATE }));
                    history.push(routes.HOME);
                })
                .catch(error => {
                    this.setState(byPropKey('error', error));
                });
            });
            

            event.preventDefault();
        }

        render() {
            const {
                email,
                password,
                error,
            } = this.state;

            const isInvalid =
                password === '' ||
                email === '';

            return (
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">

                            <div className = "vajro_font_placement"> Vajro
                            </div>

                            <Tilt className="Tilt" options={{ scale : 1.1 }} >
                            <img src={require ("../images/img-01.png")} alt="IMG" />
                            </Tilt>
                            
            
                            <form className="login100-form validate-form" onSubmit = { this.onSubmit }>
                                <span className="login100-form-title">
                                    Member Login
                                </span>
            
                                <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                                    <input className="input100" type="text" placeholder="Email" onChange = { event => this.setState(byPropKey('email', event.target.value)) } />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </span>
                                </div>
            
                                <div className="wrap-input100 validate-input" data-validate="Password is required">
                                    <input className="input100" onChange = { event => this.setState(byPropKey('password', event.target.value)) } type="password" placeholder="Password" />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <i className="fa fa-lock" aria-hidden="true"></i>
                                    </span>
                                </div>
            
                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn" disabled = { isInvalid } type = "submit">
                                        Login
                                    </button>
                                    {
                                        error && < p style = {{color : "#faf8c2"}}> { error.message } </p> 
                                    } 
                                </div>                              
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
    }

    
export default withRouter(SignInPage);

export {
    SignInForm
};