import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import * as firebase from 'firebase';
import './App.css';
import withAuthentication from './withAuthentication.jsx';
import SideBar from './Sidebar.jsx';
import LandingPage from './Landing.jsx';
import SignUpPage from './SignUp.jsx';
import SignInPage from './SignIn.jsx';
import PasswordForgetPage from './PasswordForget.jsx';

import HomePage from './Home.jsx';
import AccountPage from './Account.jsx';
import ProductionTask from './ProductionTask/ProductionTask.jsx';
import CreateKRUser from './Create_KR_user/create_user.jsx';
import Facebook_functions from './Facebook/Facebook_functions.jsx';

import * as routes from '../constants/routes.jsx';
import { css } from 'react-emotion';
// First way to import
import { ClimbingBoxLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

// const App = () =>
//   <Router>
//     <div>
// 		<Route exact path={routes.SIGN_IN} component={() => <SignInPage />} />
//       <SideBar />
// 		<div className = "accountpage_full">
// 			<Route exact path={routes.LANDING} component={() => <LandingPage />} />
// 			<Route exact path={routes.SIGN_UP} component={() => <SignUpPage />} />
// 			<Route exact path={routes.PASSWORD_FORGET} component={() => <PasswordForgetPage />} />
// 			<Route exact path={routes.HOME} component={() => <HomePage />} />
// 			<Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
// 		</div>

//     </div>
//   </Router>

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentUser : null,
			loading: true
		};
		this.getStyle = this.getStyle.bind(this);
	}

	componentWillMount() {
		const self = this;
		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				self.setState({ currentUser: true ,loading: false});
			} else {
				self.setState({ currentUser: false ,loading: false});
			}
		})
	}

	getStyle() {
		const { currentUser, loading } = this.state;
		return {
		 "zIndex" : currentUser ? 0 : -1
		}
	}
	render() {
		const { currentUser, loading } = this.state;
		if(loading) {
			return(
				<div className='sweet-loading'>
					<ClimbingBoxLoader
					className={override}
					sizeUnit={"px"}
					size={15}
					color={'#0e0e0ee3'}
					loading={loading}
					/>
				</div> 
			)
		}

		return(
			<Router>
				<div>
					{currentUser? (
						<SideBar />
					): (
						<div> </div>
					)}
					<Route exact path={routes.SIGN_IN} component={() => <SignInPage />} />
						<div id="fb-root"></div>            
					<div className = "accountpage_full" style = {this.getStyle()} >    
						<Route exact path={routes.LANDING} component={() => <LandingPage />} />
						<Route exact path={routes.SIGN_UP} component={() => <SignUpPage />} />
						<Route exact path={routes.PASSWORD_FORGET} component={() => <PasswordForgetPage />} />
						<Route exact path={routes.HOME} component={() => <HomePage />} />
						<Route exact path={routes.ACCOUNT} component={() => <AccountPage />} />
						<Route exact path={routes.PRODUCTION_TASK} component={() => <ProductionTask />} />
						<Route exact path={routes.CREATE_KR_USER} component={() => <CreateKRUser />} />
						<Route exact path={routes.FACEBOOK_FUNCTIONALITY} component={() => <Facebook_functions />} />
					</div>
				</div>
			</Router>
		);
	}
}

export default withAuthentication(App);