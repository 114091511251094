import React from 'react';
import * as firebase from 'firebase';
import './task.css'
import SplitPane from 'react-split-pane';
import TaskCard from './taskCard';
import moment from 'moment/moment.js';

class ProductionTaskComponent extends React.Component {
    constructor(props) {
        super(props)

        this.getProductionTask = this.getProductionTask.bind(this);
        this.handleTaskChange = this.handleTaskChange.bind(this);

        this.state = {
            pending_task: [],
            onHold_task: [],
            uploaded_task: [],
            live_task: [],
            submitted_task: [],
        };
    }

    componentWillMount(){
        this.getProductionTask();
    }

    getProductionTask(){
        const self = this;
		firebase.database().ref("/tasks/production" ).once('value').then(function(snapshot){
            var production_task_node = snapshot.val();
            var pending_task_array = [];
            var onHold_task_array = [];
            var uploaded_task_array = [];
            var live_task_array = [];
            var submitted_task_array = [];

            if(production_task_node){
                for(var task in production_task_node){
                    var task_node = production_task_node[task];
                    task_node.id = task;

                    
                    production_task_node[task].since_created = self.getDifferenceInTime(production_task_node[task].created_at);
                    production_task_node[task].since_updated = self.getDifferenceInTime(production_task_node[task].updated_at);
                    if(task_node.status.includes('pending') ){
                        pending_task_array.push(production_task_node[task]);
                    } else if( task_node.status.includes('uploaded')){//this on hold task array is modified later and now it contanins submitted and uploaded Task
                        uploaded_task_array.push(production_task_node[task]);
                    } else if(task_node.status.includes('live')){
                        live_task_array.push(production_task_node[task]);
                    } else if(task_node.status.includes('onhold')){
                        onHold_task_array.push(production_task_node[task]);
                    }else if(task_node.status.includes('submitted')){
                        submitted_task_array.push(production_task_node[task]);
                    }
                }

                live_task_array.sort(self.sortByProperty("updated_at"));
                pending_task_array.sort(self.sortByProperty("updated_at"));
                onHold_task_array.sort(self.sortByProperty("updated_at"));
                submitted_task_array.sort(self.sortByProperty("updated_at"));
                uploaded_task_array.sort(self.sortByProperty("updated_at"));

                self.setState({
                    pending_task : pending_task_array,
                    onHold_task :   onHold_task_array,
                    live_task : live_task_array,
                    uploaded_task: uploaded_task_array,
                    submitted_task: submitted_task_array,
                });
                
            } else{
                production_task_node = {};
                // self.setState({
                //     pending_task : pending_task_array,
                //     onHold_task :   onHold_task_array
                // });
            }
        });   
    }

    sortByProperty = function (property) {
        return function (x, y) {
            return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
        };
    };

    getDifferenceInTime(actual_time){

        var now = moment(new Date()); 
        var end = actual_time;
        end = moment(end*1000);
        var differenceTime = "";
        var difference_in_minutes = now.diff(end, 'minutes');
        var difference_in_hours = now.diff(end,'hours');
        var difference_in_days = now.diff(end,'days');
        if(difference_in_minutes < 60){
            differenceTime = difference_in_minutes + " mins ago";
        } else if (difference_in_hours < 24){
            differenceTime = difference_in_hours + " hours ago";
        } else {
            differenceTime = difference_in_days + " days ago"
        }

        return differenceTime
    }

    componentWillReceiveProps(props){
        this.getProductionTask();

    }

    handleTaskChange(task_array){
        this.getProductionTask();
    }

    render() {

        return (
            <div className = "production_task_container"> 
                <div className = "row task-container"> 
                    <span>
                         <div className = "col-md-3 vertical_pane priority_bg_color">
                            <h1>Onhold Tasks ({this.state.onHold_task.length})</h1>
                            <TaskCard onHold_task = {this.state.onHold_task} onChangeTaskArray = {this.handleTaskChange}/>
                        </div>
                        <div className = "col-md-3 vertical_pane">
                            <h1>Pending Tasks ({this.state.pending_task.length}) </h1>
                            <TaskCard pending_task = {this.state.pending_task} onChangeTaskArray = {this.handleTaskChange}/>
                        </div>
                        <div className = "col-md-3 vertical_pane">
                            <h1>Uploaded Tasks ({this.state.uploaded_task.length})</h1>
                            <TaskCard uploaded_task = {this.state.uploaded_task} onChangeTaskArray = {this.handleTaskChange}/>
                        </div>
                        <div className = "col-md-3 vertical_pane">
                            <h1>Submitted Tasks ({this.state.submitted_task.length})</h1>
                            <TaskCard submitted_task = {this.state.submitted_task} onChangeTaskArray = {this.handleTaskChange}/>
                        </div>
                        <div className = "col-md-3 vertical_pane">
                            <h1>Live Tasks ({this.state.live_task.length})</h1>
                            <TaskCard live_task = {this.state.live_task} onChangeTaskArray = {this.handleTaskChange}/>
                        </div>
                    </span>
                </div>
            </div>
        );
    }
};


export default ProductionTaskComponent