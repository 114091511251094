import React from 'react';
import * as firebase from 'firebase';
import './platform_status.css';


class FirebaseLinks extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            appid: "",
            accessKeyNode: "",
            appsNode: "",
            billingNode: "",
            nuserNode: ""
        }
    }


    componentDidUpdate(props){
        if(this.props.appid){
            if(JSON.stringify (this.props.appid) !== JSON.stringify(this.state.appid)){
                this.setState({
                        appid : this.props.appid
                });
                this.loadDbLinks(this.props.appid)
            }
        } 
    }

    loadDbLinks(appid){
        const self = this;

        this.setState({
            accessKeyNode: "https://console.firebase.google.com/u/0/project/project-950777300636680597/database/project-950777300636680597/data/accessKeys/" + appid,
            appsNode: "https://console.firebase.google.com/u/0/project/project-950777300636680597/database/project-950777300636680597/data/apps/"+ appid,
            billingNode: "https://console.firebase.google.com/u/0/project/project-950777300636680597/database/project-950777300636680597/data/extra/billing/apps/" + appid
        });

        firebase.database().ref("/apps/" + appid).once('value').then(function(snapshot) {
            if(snapshot.val()){
                var uid = snapshot.val().uid;
                self.setState({
                    nuserNode: "https://console.firebase.google.com/u/0/project/project-950777300636680597/database/project-950777300636680597/data/nusers/" + uid
                });
            } else{
                self.setState({
                    nuserNode: "https://console.firebase.google.com/u/0/project/project-950777300636680597/database/project-950777300636680597/data/nusers/"
                });
            }
        });
    }

    render(){
        return(
            <div className="row">
                <label className = "col-md-3">Firebase Links</label>
                <button className = "external_link_button app-link-button move_app_links" onClick= {() => window.open(this.state.appsNode)}>Apps Node</button>
                <button className = "external_link_button app-link-button" onClick= {() => window.open(this.state.accessKeyNode)}>Access Key Node</button>
                <button className = "external_link_button app-link-button" onClick= {() => window.open(this.state.nuserNode)}>Nuser Node</button>
                <button className = "external_link_button app-link-button" onClick= {() => window.open(this.state.billingNode)}>Billing Node</button>
                <hr className = "custom_hr_tag"/>
            </div>
        );
    }

}

export default FirebaseLinks;