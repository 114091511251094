import React from 'react';
import * as firebase from 'firebase';
import './platform_status.css';
import axios from 'axios';


class OneSignalLinks extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            appid: "",
            os_settings: "",
            os_stats: "",
            os_app_id: "",
            p12_flag: ""
        }
    }

    componentDidUpdate(props){
        if(this.props.appid){
            if(JSON.stringify (this.props.appid) !== JSON.stringify(this.state.appid)){
                this.setState({
                        appid : this.props.appid
                });
                this.loadOneSignalLinks(this.props.appid);
            }
        } 
    }

    loadOneSignalLinks(appid){
        const self = this;
        firebase.database().ref("/apps/" + appid).once('value').then(function(snapshot) {
            if(snapshot.val()){
                var uid = snapshot.val().uid;
                firebase.database().ref("/nusers/" + uid).once('value').then(function(u_snapshot) {
                    if(u_snapshot.val()){
                        if(u_snapshot.val().pushDetails){
                            self.setState({
                                os_settings: "https://onesignal.com/apps/"+ u_snapshot.val().pushDetails.onesignalAppID +"/settings",
                                os_stats: "https://onesignal.com/apps/"+ u_snapshot.val().pushDetails.onesignalAppID +"/notifications",
                                os_app_id: u_snapshot.val().pushDetails.onesignalAppID
                            });
                            // One user multiple Apps. Priority for accessKey is given first.
                            firebase.database().ref(`/accessKeys/${appid}/push_credentials`).once('value').then(function(accessSnapshot){
                                if(accessSnapshot.val()){
                                    self.setState({
                                        os_settings: "https://onesignal.com/apps/"+ accessSnapshot.val().onesignal_appid +"/settings",
                                        os_stats: "https://onesignal.com/apps/"+ accessSnapshot.val().onesignal_appid +"/notifications",
                                        os_app_id: accessSnapshot.val().onesignal_appid
                                    });
                                } 
                            })

                            var api_url = "https://onesignal.com/api/v1/apps/"+ self.state.os_app_id;
                            var config = {
                                headers: {
                                    "Authorization": "Basic NGUzMDcyNjUtOTM1Mi00NDhiLWI0NjYtYTg4MjViYzZkZTVm" ,
                                    "Content-Type": "application/json"
                                }
                            };

                            axios.get(api_url,config).then(response => {
                                var body = response.data;
                                    if(!body.errors && body){
                                        if(body.apns_certificates !== null){
                                            self.setState({
                                                p12_flag: true
                                            });
                                        } else{
                                            self.setState({
                                                p12_flag: false
                                            });
                                        }
                                    } else{
                                        self.setState({
                                            p12_flag: false
                                        });
                                    }
                            })
                            .catch((error) => {
                                console.log('Production task creation failed: ' + error);
                                self.setState({
                                    p12_flag: false
                                });
                            });
                        } else{
                            self.setState({
                                os_settings: "",
                                os_stats: "",
                                os_app_id: "",
                                p12_flag:false
                            });
                        } 
                    } else{
                        self.setState({
                            os_settings: "",
                            os_stats: "",
                            os_app_id: "",
                            p12_flag:false
                        });
                    }
                });
            } else{
                self.setState({
                    os_settings: "",
                    os_stats: "",
                    os_app_id: "",
                    p12_flag:false
                });
            }
        });
    }


    render(){
        return(
            <div>
                <div className = "row">
                    <label className = "col-md-3"> P12 Uploaded </label>
                    {this.state.p12_flag ? (
                            <i className="fas fa-check correct_icon_status"></i>   
                        ) : (
                            <i className="fas fa-times wrong_icon_status"></i>
                        )}
                </div>
                <div className="row">
                    <label className = "col-md-3"> One Signal </label>
                    <button className = "external_link_button app-link-button move_app_links" onClick= {() => window.open(this.state.os_settings)}>Settings</button>
                    <button className = "external_link_button app-link-button" onClick= {() => window.open(this.state.os_stats)}>Stats</button>
                </div>
                <hr className = "custom_hr_tag"/>
                
            </div>
        )
    }
}

export default OneSignalLinks;
