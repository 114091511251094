import './platform_status.css';

import * as firebase from 'firebase';

import { Button } from 'reactstrap'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import React from 'react';
import { notify } from 'react-notify-toast';

class PlatformAppStatus extends React.Component{
    constructor(props){
        super(props);
    
        this.state = { 
            appid: "",
            paid_status: false,
            store_listing_completed: false,
            storefront_access_token: false,
            invite_sent_android: false,
            invite_sent_ios : false,
            live_android : false,
            live_ios : false,
            iOS_link : "",
            android_link : "",
            android_team_id_url :"",
            android_package: "",
            ios_dev_team_id: "",
            vajro_plan: "",
            app_installed:""
        }
    }

    // componentDidUpdate(props){
    //     if(this.props.appid){
    //         if(JSON.stringify (this.props.appid) !== JSON.stringify(this.state.appid)){
    //             this.loadAppValues(this.props.appid)
    //         }
    //     } 
    // }

    componentWillReceiveProps(props){
        if(this.props.appid){
            this.loadAppValues(this.props.appid)
        } 
    }

	showNotification(){
		
		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};
		notify.show("Copied Successfully", "success", 500, myColor);
    }
    
    loadAppValues(appid){

        const self = this;
       
        firebase.database().ref("/extra/billing/apps/" + appid).once('value').then(function(billing_node) {
            if(billing_node.val()){
                if(billing_node.val().status){
                    if(billing_node.val().status.paid){
                        self.setState({
                            paid_status: billing_node.val().status.paid
                        });
                    } else {
                        self.setState({
                            paid_status: false
                        });
                    } 
                } else {
                    self.setState({
                        paid_status: false
                    });
                }

                if(billing_node.val().plan){
                    if(billing_node.val().plan.name){
                        self.setState({
                            vajro_plan: billing_node.val().plan.name
                        });
                    } else{
                        self.setState({
                            vajro_plan: ""
                        });
                    }
                } else{
                    self.setState({
                        vajro_plan: ""
                    });
                }
            } else {
                self.setState({
                    paid_status: false,
                    vajro_plan: ""
                });
            }
        });
        
        firebase.database().ref("/extra/preview/apps/" + appid ).once('value').then(function(devAccountDetails){
            if(devAccountDetails.val()){
                if(devAccountDetails.val().dev_account){
                    if(devAccountDetails.val().dev_account.ios){
                        self.setState({
                            ios_dev_team_id: "https://developer.apple.com/account/#/overview/"+ devAccountDetails.val().dev_account.ios.team_id
                        });
                    } else{
                        self.setState({
                            ios_dev_team_id: ""
                        });
                    }
                } else{
                    self.setState({
                        ios_dev_team_id: ""
                    });
                }
            } else{
                self.setState({
                    ios_dev_team_id: ""
                });
            }
        });

        firebase.database().ref('/apps/'+ appid + "/apidata").once('value').then(function(snapshot){
            if(snapshot.val()){

                if(snapshot.val().shopify_storefront_accesstoken){
                    self.setState({
                        storefront_access_token: true
                    });
                } else{
                    self.setState({
                        storefront_access_token: false
                    });
                }
                if(snapshot.val().shopify_app_installed){
                    self.setState({
                        app_installed: true
                    });
                }else{
                    self.setState({
                        app_installed: false
                    });
                }
            } else{
                self.setState({
                    storefront_access_token:false,
                    app_installed: false
                });
            }
        });

        firebase.database().ref("/apps/"+ appid).once('value').then(function(apps_snapshot){
            if(apps_snapshot.val()){

                self.setState({
                    android_package: apps_snapshot.val().storedata.android_package_name,
                });


                var nusers_uid = apps_snapshot.val().uid;
                firebase.database().ref(`/extra/preview/apps/${appid}/appstatus`).once('value').then(function(u_snapshot){
                    if(u_snapshot.val()) {
                        var appstatus_node = u_snapshot.val();
                        if(appstatus_node) {
                            self.setState({
                                invite_sent_android: appstatus_node[1] ? appstatus_node[1].android || false : false,
                                invite_sent_ios : appstatus_node[1] ? appstatus_node[1].ios || false : false,
                                live_android : appstatus_node[3] ? appstatus_node[3].android || false : false,
                                live_ios : appstatus_node[3] ? appstatus_node[3].ios || false : false
                            });
                        } else {
                            self.setState({
                                invite_sent_android: false,
                                invite_sent_ios : false,
                                live_android : false,
                                live_ios : false
                            });
                        }
                        firebase.database().ref(`nusers/${nusers_uid}/apps`).once('value').then(function(nuser_snapshot){
                            let appLinkURL = nuser_snapshot.val();
                            if(appLinkURL){
                                self.setState({
                                    iOS_link : appLinkURL.ios.url,
                                    android_link : appLinkURL.android.url
                                });
                            } else{
                                self.setState({
                                    iOS_link : "",
                                    android_link : ""
                                });
                            }
                        })
                        // patch up code for auto update package name in vajro server backend
                        let live_android_url = self.state.android_link;
                        if(live_android_url && self.state.android_link !== "" && !live_android_url.includes(self.state.android_package)){
                            self.setState({
                                "android_link" : "https://play.google.com/store/apps/details?id=" + self.state.android_package 
                            });

                            var nuserRef = firebase.database().ref('/nusers/' + nusers_uid + '/apps/');
                            nuserRef.update({
                                "android": {
                                    "url": self.state.android_link
                                }           
                            });
                            
                        }


                        //call preview node here
                        firebase.database().ref("/extra/preview/apps/" + appid ).once('value').then(function(devAccountDetails){
                            if(devAccountDetails.val()){
                                if(devAccountDetails.val().dev_account){
                                    if(devAccountDetails.val().dev_account.android){
                                        if(self.state.live_android){
                                            self.setState({
                                                android_team_id_url: "https://play.google.com/apps/publish/?account=" + devAccountDetails.val().dev_account.android.team_id + "#AppDashboardPlace:p=" + self.state.android_package
                                            });
                                        } else{
                                            self.setState({
                                                android_team_id_url: "https://play.google.com/apps/publish/?account=" + devAccountDetails.val().dev_account.android.team_id 
                                            });
                                        }
                                        
                                    } else{
                                        self.setState({
                                            android_team_id_url: ""
                                        });
                                    }
                                } else{
                                    self.setState({
                                        android_team_id_url: ""
                                    });
                                }
                                if(devAccountDetails.val().store_listing){
                                    if(devAccountDetails.val().store_listing.finished){
                                        self.setState({
                                            store_listing_completed : devAccountDetails.val().store_listing.finished
                                        });
                                    } else {
                                        self.setState({
                                            store_listing_completed : false
                                        });
                                    }
                                } else{
                                    self.setState({
                                        store_listing_completed : false
                                    });
                                }
                            } else{
                                self.setState({
                                    store_listing_completed : false
                                });
                            }
                        });	
                    } else {
                        self.setState({
                            invite_sent_android: false,
                            invite_sent_ios : false,
                            live_android : false,
                            live_ios : false
                        });
                    }
                });
            } else {
                self.setState({
                    invite_sent_android: false,
                    invite_sent_ios : false,
                    live_android : false,
                    live_ios : false
                });
            }
            
        });     
        
       
    }

    render(){
        const self = this;

        return(
            <div>
                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">App Installed</label> 
                    {this.state.app_installed ? (
                        <div>
                            <i className="fas fa-check correct_icon_status"></i>
                        </div>
                    ) : (
                        <div>
                            <i className="fas fa-times wrong_icon_status"></i>
                        </div>
                    )}
                </div>
                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">Paid Status</label> 
                    {this.state.paid_status ? (
                        <div>
                            <i className="fas fa-check correct_icon_status"></i>
                            <label className = "vajro-plan-status">{this.state.vajro_plan}</label>
                        </div>
                    ) : (
                        <div>
                            <i className="fas fa-times wrong_icon_status"></i>
                            <label className = "vajro-plan-status">{this.state.vajro_plan}</label>
                        </div>
                    )}
                </div>
                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">Storefront Access Token</label> 
                    {this.state.storefront_access_token ? (
                        <i className="fas fa-check correct_icon_status"></i>
                    ) : (
                        <i className="fas fa-times wrong_icon_status"></i>
                    )}
                </div>
                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">Store Listing Completed</label> 
                    {this.state.store_listing_completed ? (
                        <i className="fas fa-check correct_icon_status"></i>
                    ) : (
                        <i className="fas fa-times wrong_icon_status"></i>
                    )}
                </div>
                <hr className = "custom_hr_tag"/>
                
                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">Google Dev Account</label> 
                    {this.state.invite_sent_android ? (
                        <div>
                            <i className="fas fa-check correct_icon_status"></i>
                            <CopyToClipboard text={this.state.android_team_id_url} >
                                <Button className="copy_icon_button button-hover refresh-link-button-custom-css" onClick = {this.showNotification} ><i className="far fa-copy"></i> </Button>
                            </CopyToClipboard>
                            <button className = "external_link_button open-link-button-custom-css" onClick= {() => window.open(self.state.android_team_id_url)}><i className="fas fa-external-link-alt"></i></button>
                        </div>
                    ) : (
                        <i className="fas fa-times wrong_icon_status"></i>
                    )}
                </div>
                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">Android Live</label> 
                    {this.state.live_android ? (
                        <div>
                            <i className="fas fa-check correct_icon_status"></i>
                            <CopyToClipboard text={this.state.android_link} >
                                <Button className="copy_icon_button button-hover refresh-link-button-custom-css" onClick = {this.showNotification} ><i className="far fa-copy"></i> </Button>
                            </CopyToClipboard>
                            <button className = "external_link_button open-link-button-custom-css" onClick= {() => window.open(self.state.android_link)}><i className="fas fa-external-link-alt"></i></button>
                        </div>
                    ) : (
                        <i className="fas fa-times wrong_icon_status"></i>
                    )}
                    
                </div>
                <hr className = "custom_hr_tag"/>

                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">Apple Dev Account</label> 
                    {this.state.invite_sent_ios ? (
                        <div>
                            <i className="fas fa-check correct_icon_status"></i>
                            <CopyToClipboard text={this.state.ios_dev_team_id} >
                                <Button className="copy_icon_button button-hover refresh-link-button-custom-css" onClick = {this.showNotification} ><i className="far fa-copy"></i> </Button>
                            </CopyToClipboard>
                            <button className = "external_link_button open-link-button-custom-css" onClick= {() => window.open(self.state.ios_dev_team_id)}><i className="fas fa-external-link-alt"></i></button>
                        </div>                    ) : (
                        <i className="fas fa-times wrong_icon_status"></i>
                    )}
                </div>

                <div className = "row status_container_alignment_custom1">
                    <label className = " col-md-3">iOS Live</label> 
                    {this.state.live_ios ? (
                        <div>
                            <i className="fas fa-check correct_icon_status"></i>
                            <CopyToClipboard text={this.state.iOS_link} >
                                <Button className="copy_icon_button button-hover refresh-link-button-custom-css" onClick = {this.showNotification} ><i className="far fa-copy"></i> </Button>
                            </CopyToClipboard>
                            <button className = "external_link_button open-link-button-custom-css" onClick= {() => window.open(self.state.iOS_link)}><i className="fas fa-external-link-alt"></i></button>
                        </div>
                    ) : (
                        <i className="fas fa-times wrong_icon_status"></i>
                    )}
                    
                </div>
                <hr className = "custom_hr_tag"/>
            </div>
        )
    }


}

export default PlatformAppStatus;