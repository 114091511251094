import AuthUserContext from './AuthUserContext';
import React from 'react';
import { auth } from '../firebase';
import { withRouter } from 'react-router-dom';
import * as routes from '../constants/routes.jsx';

const Sidebar = ({history}) =>
  <AuthUserContext.Consumer>
    {authUser => authUser
      ? <SidebarAuth history={history}/>
      : <SidebarNonAuth />
    }
  </AuthUserContext.Consumer>

class SidebarAuth extends React.Component {
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){

        var target = e.target.id;
        if(target === "production"){
            this.props.history.push(routes.PRODUCTION_TASK);
            return;
        } else if(target === "account"){
            this.props.history.push(routes.ACCOUNT);
            return;
        } else if(target === "home"){
            this.props.history.push(routes.HOME);
            return;
        }else if(target === "create_kr_user"){
            this.props.history.push(routes.CREATE_KR_USER);
            return;
        }
        
    }


    render(){
        return(
            <div className="wrapper">
                <nav id="sidebar">
                    <div className="sidebar-header">
                        <h3 className = "vajro_font">Vajro</h3>
                    </div>

                    <ul className="list-unstyled components">
                        <li className="active"><a onClick = {this.handleChange} id="home">Home</a></li>
                        <li><a onClick = {this.handleChange} id="account">Account</a></li>
                        <li><a onClick = {this.handleChange} id="production" >Production Task</a></li>
                        <li><a onClick = {this.handleChange} id="create_kr_user" >Create KR User</a></li>
                        <li><a onClick={auth.doSignOut}>Sign Out</a></li>
                    </ul>
                </nav>
            </div>
        );
    }
       
}      
const SidebarNonAuth = () =>
        <div></div>
        // <div className="wrapper hide">
        //     <nav id="sidebar">
        //         <div className="sidebar-header">
        //             <h3 className = "vajro_font">Vajro</h3>
        //         </div>

        //         <ul className="list-unstyled components">
        //             <li><a href="/signin">Log In </a></li>
        //         </ul>
        //     </nav>
        // </div>

export default withRouter(Sidebar);