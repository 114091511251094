import React from 'react';
import './facebook.css';
/*global FB*/

class FacebookLive extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            embededLiveVideo : ""
        }
        this.facebookGoLive = this.facebookGoLive.bind(this);
        this.displayLiveVideo = this.displayLiveVideo.bind(this);
        this.displayLiveVideoComments = this.displayLiveVideoComments.bind(this);
    }

    facebookGoLive() {

        const self = this;
        var token = "";
        var facebookPageId = "";
        var finalLiveUrl = "";

        FB.getLoginStatus(function(response) {
            if (response.status === 'connected') {

                // FB.api('/me/permissions', function(response) {
                //     console.log("Permission");
                //     console.log(JSON.stringify(response));       
                // }); 

                FB.api('/me/accounts', function(response) { 
                    
                    token = response.data[0].access_token;
                    facebookPageId = response.data[0].id;
                    finalLiveUrl = '/' + facebookPageId + '/live_videos';
                    console.log(finalLiveUrl);
                    FB.api(
                        finalLiveUrl,
                        {
                            "status":"LIVE_NOW",
                            "title":"\"Today's Page Live Video\"",
                            "description":"\"This is the live video for the Page for today.\"",
                            "access_token": token
                        },
                        function(response) {
                            console.log("Live Video Data:");
                            console.log(JSON.stringify(response));
                            if(response && !response.error){
                                response.data.forEach(element => {
                                    if(element.status === "LIVE"){
                                        self.setState({
                                            "embededLiveVideo": element.embed_html
                                        });
                                        var liveVideoCommentsUrl = "/" + element.id + "/comments";  
                                        FB.api(liveVideoCommentsUrl, function(commentsResponse) { 
                                            self.setState({
                                                "comments": commentsResponse.data
                                            });
                                        });

                                    }
                                });
                            }
                        }
                    );
                });
            }
        });
    }

    displayLiveVideo(){
        return(
            <div className = "LiveVideoDisplayContainer">
                <td dangerouslySetInnerHTML={{__html: this.state.embededLiveVideo}} />
            </div>
        );
    }

    displayLiveVideoComments(){
        return(
            <div className = "LiveVideoCommentsContainer">
                <h2> Comments </h2>
                {this.state.comments}
            </div>
        );
    }

    render(){
        return(
            <div className = "FacebookLiveContainer">
                <button className = "facebookLiveButton row" onClick= {this.facebookGoLive} > Fetch Live </button>
                {this.displayLiveVideo()}
                {this.displayLiveVideoComments()}
            </div>  
        );
    }

}

export default FacebookLive