import React from 'react';
import * as firebase from 'firebase';

import { notify } from 'react-notify-toast';
import './App.css';


class StoreListing extends React.Component {

	constructor(props) {
		super(props);

		this.loadAppStatus.bind(props.data)
		this.loadAppStatus = this.loadAppStatus.bind(this);

		this.state = {
			appid: "",
			website: "",
			title: "",
			keywords: "",
			short_desc: "",
			long_desc: "",
			appLogo: "",
			packageName: "",
			policyLink: "",
			supportMail: "",
			phoneNumber: "",
			api: "",
			customer_name: "",
			copyright_name: ""
		}
	}

	componentWillReceiveProps(){
		if(this.props.data.length > 3){
			this.loadAppStatus(this.props.data);
		}
	}
  
	loadAppStatus(appid) {
		let myColor = {
			background: '#0bdee5',
			text: "#FFFFFF"
		};

		this.setState({
			appid: appid
		});

		const self = this;
		
		firebase.database().ref("/extra/preview/apps/" + appid ).once('value').then(function(devAccountDetails){
			if(devAccountDetails.val()){
				if(devAccountDetails.val().dev_account){
					if(devAccountDetails.val().dev_account.ios){
						self.setState({
							copyright_name: devAccountDetails.val().dev_account.ios.team_name
						});
					} else {
						self.setState({
							copyright_name: ""
						});
					}
				} else {
					self.setState({
						copyright_name: ""
					});
				}
			} else{
				self.setState({
					copyright_name: ""
				});
			}

			firebase.database().ref("/extra/preview/apps/" + appid).once('value').then(function(snapshot) {
				if (snapshot.val()) {
					self.setState({
						website: snapshot.val().website,
						// title: snapshot.val().name,
						appLogo: snapshot.val().logo,
						customer_name: snapshot.val().customer_name,
						api:"http://cryptic-sands-48084.herokuapp.com/download_image_from_url?url=" + snapshot.val().logo + "&image_name="+ appid +".png"
					});

					if(!self.state.copyright_name && snapshot.val().name){
						self.setState({
							copyright_name: snapshot.val().name
						});
					}

					if(snapshot.val().store_listing){

						self.setState({
							keywords: snapshot.val().store_listing.keywords,
							short_desc: snapshot.val().store_listing.shortDescription,
							long_desc: snapshot.val().store_listing.longDescription,
							policyLink: snapshot.val().store_listing.policy_link,
							supportMail: snapshot.val().store_listing.mail,
							phoneNumber: snapshot.val().store_listing.phone_number,
							title:snapshot.val().store_listing.appName
						});
					} else{
						self.setState({
							keywords: "",
							short_desc: "",
							long_desc: "",
							policyLink: "",
							supportMail: "",
							title:"",
						});
					}
					notify.show("Loaded Successfully", "success", 3000, myColor)
				} else {
					notify.show("Invalid Appid", "error", 3000, myColor)
					console.log("Invalid Appid");
				}
			}).catch(function(error) {
				console.log(error);
				notify.show("Invalid data", "error", 3000, myColor)
			});
		});
	}



  render() {
    return (
      <div className="container-fluid">
		    <div className = "row">
		    </div>
		    <div>
		    <div className = "row">
			    <label className = " col-md-3">App Id : </label> 
			    <div>{this.state.appid}</div> <br />
			</div>
			<div className = "row">
			    <label className = " col-md-3">Website : </label> 
			    <div>{this.state.website} </div><br />
			</div> 
      		<div className = "row">
			    <label className = " col-md-3">Customer Name : </label> 
			    <div>{this.state.customer_name} </div><br />
			</div>  
			<div className = "row">  
			    <label className = " col-md-3">App Title : </label> 
			    <div>{this.state.title} </div><br />
			</div>  
            <div className = "row">
			    <label className = " col-md-3">Phone Number : </label> 
			    <div>{this.state.phoneNumber} </div><br />
			</div>  
            <div className = "row">
			    <label className = " col-md-3">Copyrights : </label> 
			    <div> Copyright © 2023 All rights reserved. </div><br />
			</div>
			<div className = "row">
			    <label className = " col-md-3">Keywords : </label> 
			    <div> {this.state.keywords} </div> <br />
			</div>  
			<div className = "row">
			    <label className = " col-md-3">Short Description : </label> 
			    <div><textarea value=
      				{this.state.short_desc} cols={100} rows={2} /></div><br />
			</div>  
			<div className = "row">
			    <label className = "col-md-3 " >Long Description : </label> <div><textarea className = "textareaScrollbarDesc" value={this.state.long_desc} cols={100} rows={5} /> </div><br />
			</div>  
			<div className = "row">
			    <label className = " col-md-3">App Logo : </label> 
                <div>
                    <input className = "input_appinfo_applogo" value={this.state.appLogo} ></input>
                    <button className = "external_link_button" onClick= {() => window.open(this.state.appLogo)}><i className="fas fa-external-link-square-alt"></i></button>
                    <button className = "download_link_button" onClick = {() => window.open(this.state.api)}><i className="fa fa-download"></i></button>
                </div><br />
			</div>  
			<div className = "row">
			    <label className = " col-md-3">Privacy Policy URL : </label> <div>{this.state.policyLink} </div><br />
			</div>  
			<div className = "row">
			    <label className = " col-md-3">Support Email : </label> <div> {this.state.supportMail} </div> <br />
			</div>
	    </div>
   </div>
      );

  }
}

export default StoreListing
