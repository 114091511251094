import "./App.css";

import * as firebase from 'firebase';

import { ButtonToolbar, Dropdown, DropdownButton, Glyphicon, MenuItem } from 'react-bootstrap';
import { Panel, PanelGroup } from 'react-bootstrap'

import React from 'react';
import { ScaleLoader } from 'react-spinners';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { notify } from 'react-notify-toast';

class AppStatus extends React.Component {
	constructor(props) {
		super(props);
	
		this.loadAppStatus = this.loadAppStatus.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.loadAppStatus.bind(props.database)
		this.updateDatabase = this.updateDatabase.bind(this);
		this.hideAlert = this.hideAlert.bind(this);
		this.saveToDB = this.saveToDB.bind(this);
		this.showNotification = this.showNotification.bind(this);
		this.handleAppstatusChangeCallback = this.handleAppstatusChangeCallback.bind(this);

		this.state = {
			items: [{
			android: false,
			ios: false
			}, {
			android: false,
			ios: false
			}, {
			android: false,
			ios: false
			}, {
			android: false,
			ios: false
			}],
			checkbox_value: true,
			activeKey: '1',
			appid: "",
			alert: "",
			androidLink: "",
			iosLink: "",
			android_package: "",
			ios_package: "",
			ios_appid: "",
			storeName:"",
			storeUrl:"",
			showClass:"hide",
			appStatusFlag: false,
			ipad_value : false,
			splashScreenValue : "small",
		}
	}

	componentWillReceiveProps(){
		if(this.props.data.length > 3){
			this.loadAppStatus(this.props.data);
		}
	}

	handleAppstatusChangeCallback(){
		this.props.getApp(); // this is callback function which will update the value in the parent component
	}

	showNotification(){
		
		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};
		notify.show("Copied Successfully", "success", 500, myColor);
	}

	updateDatabase(index, platform, value) {
		
		let myColor = {
			background: '#50e256',
			text: "#FFFFFF"
		};
		notify.show("Updated Successfully", "success", 3000, myColor);
		var appid = this.state.appid;
		firebase.database().ref(`/apps/${this.state.appid}/uid`).once('value').then(function(snapshot) {
			if (snapshot.val()) {
			let db = firebase.database().ref(`/extra/preview/apps/${appid}/appstatus/${index}/`);

			// this is to create a production task on app invite
			if(index == 1 && value){
				var api = "https://vajrostaging.herokuapp.com/create_production_task_on_invite?appid=" + appid;
				var config = {
					headers: {"Content-Type": "application/json"}
				 };
				axios.get(api,config).then(response => {
					var body = response.data;
				   	if(body.status === "success"){
				   	}else{
				   	}
				})
				.catch((error) => {
				   console.log('Production task creation failed: ' + error);
				});
			}
			if (platform === "a") {
				db.update({
				"android": value
				});
			} else {
				db.update({
				"ios": value
				});
			}
			} else {
			notify.show("Invalid Appid", "error", 3000, myColor)

			}
		}).catch(function(error) {
			console.log(error);
			notify.show("Permission Denied", "error", 3000, myColor)
		});
	}

	handleInputChange(index, platform, event, val) {
		const target = event.target;
		var value;
		if (val !== undefined){
			value = val;
		}else{
			value = target.type === 'checkbox' ? target.checked : target.value;
		}
		
		if (value) {
			this.saveToDBWithoutPopup(index, platform, event.target);
		} else {
			this.popupThisGoal(index, platform, event.target);
		}
	}

	saveToDB(index, platform, event_target) {
		this.handleAppstatusChangeCallback();
		const target = event_target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		value = !value;
		let array_var = this.state.items[index] || {ios: false, android: false};

		if (platform === "a") {
			array_var.android = value;

			this.updateDatabase(index, platform, value);
			this.setState({
				array_var
			});
		} else {
			array_var.ios = value;
			this.updateDatabase(index, platform, value);
			this.setState({
				array_var
			});
		}
	}

	saveToDBWithoutPopup(index, platform, event_target) {
		
		this.handleAppstatusChangeCallback();
		const target = event_target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		let array_var = this.state.items[index] || {ios: false, android: false};

		if (this.state.appStatusFlag) {
			if (platform === "a") {
				array_var.android = value;
			
				this.updateDatabase(index, platform, value);
				this.setState({
					array_var
				});
			} else {
				array_var.ios = value;
				this.updateDatabase(index, platform, value);
				this.setState({
					array_var
				});
			}
		} else {
			notify.show("App Status missing ", "error", 3000);
		}

	}


	popupThisGoal(index, platform, event_target) {

		if(this.state.appStatusFlag){
			const getAlert = () => (
					<SweetAlert
					warning
					showCancel
					confirmBtnText="Yes, change it!"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="default"
					title="Are you sure?"
					onConfirm={ () => {
						this.saveToDB(index, platform, event_target);
						this.hideAlert();
					}
					}
					onCancel={this.hideAlert}
					>
					</SweetAlert>
				);
			
			this.setState({
				alert: getAlert()
			});
		} else { 
			notify.show("App Status missing ", "error", 3000)
		}
	}

	handleSelect(activeKey) {
		this.setState({
			activeKey
		});
	}

	loadAppStatus(appid) {

		let myColor = {
			background: '#2196F3',
			text: "#FFFFFF"
		};
		this.setState({
			appid: appid
		});
		notify.show("Loading ....", "custom", 1500, myColor)
		const self = this;
		firebase.database().ref("/apps/" + appid).once('value').then(function(snapshot) {
			if (snapshot.val()) {
			firebase.database().ref("/nusers/" + snapshot.val().uid).once('value').then(function(u_snapshot) {
				if (u_snapshot.val()) {
					if(u_snapshot.val().appstatus) {
						firebase.database().ref(`/extra/preview/apps/${appid}/appstatus/`).once('value').then(function(appStatus) {
							self.setState({
								appStatusFlag: true,
								items: appStatus.val() ? appStatus.val() : self.state.items,
								androidLink: u_snapshot.val().apps.android.url,
								iosLink: u_snapshot.val().apps.ios.url
							});
						})
					} else {
						self.setState({
							appStatusFlag: false,
						});
						notify.show("App Status missing ", "error", 3000, myColor)

					}
					firebase.database().ref("/apps/" + appid + "/").once('value').then(function(package_snapshot) {
						self.setState({
							android_package: package_snapshot.val().storedata.android_package_name,
							ios_package: package_snapshot.val().storedata.ios_package_name,
							ios_appid: package_snapshot.val().storedata.ios_appid,
							storeName:package_snapshot.val().name,
							storeUrl:package_snapshot.val().storeUrl
						});
					});

					firebase.database().ref("/extra/preview/apps/" + appid ).once('value').then(function(devAccountDetails){
						if(devAccountDetails.val()){

							if(devAccountDetails.val().splash_size){// this condition to initiate value for splash screen
								self.setState({
									splashScreenValue : devAccountDetails.val().splash_size
								});
							} else{
								self.setState({
									splashScreenValue : "small"
								});
							}

							if(devAccountDetails.val().ipad){
								self.setState({
									ipad_value : devAccountDetails.val().ipad
								});
							} else{
								self.setState({
									ipad_value : false
								});
							}	
							 
							if(devAccountDetails.val().dev_account){
								if(devAccountDetails.val().dev_account.android){
									self.setState({
										android_playstore_app_id: devAccountDetails.val().dev_account.android.playstore_app_id,
										android_team_id_url: "https://play.google.com/apps/publish/?account=" + devAccountDetails.val().dev_account.android.team_id,
										android_team_id: devAccountDetails.val().dev_account.android.team_id,
										android_team_name: devAccountDetails.val().dev_account.android.team_name
									});
								} else{
									self.setState({
										android_playstore_app_id: "",
										android_team_id: "",
										android_team_name: "",
										android_team_id_url: ""
									});
								}

								if(devAccountDetails.val().dev_account.ios){
									self.setState({
										ios_dev_emailid: devAccountDetails.val().dev_account.ios.emailid,
										ios_dev_password: devAccountDetails.val().dev_account.ios.password,
										ios_dev_team_id: devAccountDetails.val().dev_account.ios.team_id,
										ios_dev_team_name: devAccountDetails.val().dev_account.ios.team_name
									});
								} else{
									self.setState({
										ios_dev_emailid: "",
										ios_dev_password: "",
										ios_dev_team_id: "",
										ios_dev_team_name: ""
									});
								}
							} else{
								self.setState({
									android_playstore_app_id: "",
									android_team_id: "",
									android_team_name: "",
									android_team_id_url: "",
									ios_dev_emailid: "",
									ios_dev_password: "",
									ios_dev_team_id: "",
									ios_dev_team_name: ""
								});
							}
							
						} else{
							self.setState({
								android_playstore_app_id: "",
								android_team_id: "",
								android_team_name: "",
								android_team_id_url: "",
								ios_dev_emailid: "",
								ios_dev_password: "",
								ios_dev_team_id: "",
								ios_dev_team_name: ""
							});
						}
					})
					self.state.showClass = "";
					notify.show("Loaded Successfully", "custom", 3000, myColor)
					// console.log(self.state.items);
				} else {
					console.log("No App Status for this ID");
				}
			}).catch(function(error) {
				console.log(error);
				notify.show("Permission Denied", "error", 3000, myColor)
			})
			} else {
				notify.show("Invalid Appid", "error", 3000, myColor)
				console.log("Invalid Appid");
			}
		}).catch(function(error) {
			console.log(error);
			notify.show("Permission Denied", "error", 3000, myColor)
		});
	}



	hideAlert() {
		this.setState({
			alert: null
		});
	}

	handleSplashScreenChange(e){
		this.setState({
			splashScreenValue : e
		});
		let db = firebase.database().ref('/extra/preview/apps/'+this.state.appid);
		db.update({"splash_size":e});
	}

	handleIpadValueChange(event){

		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		
		this.setState({
			ipad_value : value
		});
		let db = firebase.database().ref('/extra/preview/apps/'+this.state.appid);
		db.update({"ipad":value});
	}

	splashScreenValue(){
		const self = this;
		return(	
			<Panel >
				<Panel.Heading>
				<Panel.Title toggle>Splash</Panel.Title>
				</Panel.Heading>
				<Panel.Body > 
					<ul className="list-group">
					<li className="list-group-item row" style={{
				margin: "0px 0px 0px 0px"
			}}>
					<div className="pull-left">Splash Size</div>
					<label className="switch pull-right" >
					<div className = "react_dropdown"> 
						<ButtonToolbar className = "dropdown-custom-2">
							<Dropdown id="dropdown-custom-1" >
							<Dropdown.Toggle>
								{self.state.splashScreenValue}
							</Dropdown.Toggle>
							<Dropdown.Menu className="super-colors"  onSelect = {e =>self.handleSplashScreenChange(e)} >
							<MenuItem eventKey="small">Small</MenuItem>
							<MenuItem eventKey="medium">Medium</MenuItem>
							<MenuItem eventKey="large">Large</MenuItem>
							<MenuItem divider />
							</Dropdown.Menu>
							</Dropdown>
						</ButtonToolbar>
					</div>
					</label>
					</li>
				
					<li className="list-group-item row" style={{
				margin: "0px 0px 0px 0px"
			}}>
					<div className="pull-left">iPad</div>
					<label className="switch pull-right" >
					<input type="checkbox" checked = {self.state.ipad_value} onChange={(e) => self.handleIpadValueChange(e)}/>
					<span className="slider round"></span>
					</label>
					</li>
					</ul>
				</Panel.Body>
			</Panel>
		);
	}

	render() {
		const self = this;
		var content_value = ["Create Apple & Google Developer Account", "Invite Vajro to access your Developer accounts", "Apps uploaded to App Stores", "Apps approved and made live"];
		var nameList = content_value.map(function(name, index) {
			let isIosEnabled = self.state.items[index] ? self.state.items[index].ios || false : false;
			let isAndroidEnabled = self.state.items[index] ? self.state.items[index].android || false : false;
			return ( 
				<div>
					<Panel eventKey={index}>
					<Panel.Heading>
					<Panel.Title toggle>{name}</Panel.Title>
					</Panel.Heading>
					<Panel.Body > 
						<ul className="list-group">
						<li className="list-group-item row" style={{
					margin: "0px 0px 0px 0px"
				}}>
						<div className="pull-left">Apple Developer Account</div>
						<label className="switch pull-right" >
						<input type="checkbox" checked={isIosEnabled} onChange={(e) => self.handleInputChange(index, "i", e, isIosEnabled)}  />
						<span className="slider round"></span>
						</label>
						</li>
						<li className="list-group-item row" style={{
					margin: "0px 0px 0px 0px"
				}}>
						<div className="pull-left">Google Developer Account</div>
						<label className="switch pull-right" >
						<input type="checkbox" checked={isAndroidEnabled} onChange={(e) => self.handleInputChange(index, "a", e, isAndroidEnabled) }  />
						<span className="slider round"></span>
						</label>
						</li>
						</ul>
					</Panel.Body>
				</Panel>
					
				</div>
			)
		});

		

		return <div className="container-fluid">
			<div className = "row showAppInfo">
				{this.state.alert}
				<span className={this.state.showClass}><b>{this.state.storeName}  </b> <a className = "anchor_link" href = {this.state.storeUrl} target="_blank">{this.state.storeUrl}</a> </span>             
			</div>
			<PanelGroup
			accordion
			id="accordion-controlled-example"
			activeKey={this.state.activeKey}
			onSelect={this.handleSelect}
			>
				{this.splashScreenValue()}
				{nameList}
			</PanelGroup>
		</div>
	}

}

export default AppStatus
