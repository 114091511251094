import React from 'react';
import withAuthorization from '../withAuthorization.jsx';
// import '../App.css';
import './task.css'
import ProductionTaskComponent from './ProductionTaskComponent.jsx';
import CreateTaskButton from './CreateTaskButton.jsx';
import Notifications from 'react-notify-toast';

class ProductionTask extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            call_get_db_func:""
        };

        
        this.updateTaskChange = this.updateTaskChange.bind(this);
    }

    updateTaskChange(value){
        this.setState({
            call_get_db_func : "trigger function"
        })
    }
    render(){
        return(
            <div className = "container-fluid">
                <div className = "production_task">
                    <Notifications />
                    <CreateTaskButton onTaskCreated = {this.updateTaskChange}  />
                    <ProductionTaskComponent call_get_db_func = {this.state.call_get_db_func}/>
                </div>
            </div>
        );
    }
} 
    

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ProductionTask);