import React from 'react';
import * as firebase from 'firebase';
import { notify } from 'react-notify-toast';
import PlatformAppStatus from './PlatformAppStatus.jsx';
import CrashAnalytics from './CrashAnalytics.jsx';
import FirebaseLinks from './firebaseLinks.jsx';
import OneSignalLinks from './oneSignal.jsx';

class StatusLinkTab extends React.Component{
    constructor(props){
        super(props);
    
        this.state = { 
            appid: "",
        }
    }

    componentWillReceiveProps(){
		if(this.props.data.length > 3){
			this.loadAppStatus(this.props.data);
		}
    };
    
    loadAppStatus(appid) {

		let myColor = {
			background: '#2196F3',
			text: "#FFFFFF"
		};
		this.setState({
			appid: appid
		});
        notify.show("Loading ....", "custom", 1500, myColor)
    }


    render(){
        return(
            <div className="container-fluid">
                <PlatformAppStatus appid = {this.state.appid} />
                <OneSignalLinks appid = {this.state.appid} />
                <FirebaseLinks appid = {this.state.appid} />
                <CrashAnalytics appid = {this.state.appid} />
            </div>
        )
    }


}

export default StatusLinkTab;