import React from 'react';
import * as firebase from 'firebase';
import { notify } from 'react-notify-toast';
import UTaskCard from './UTaskCard.jsx';
import moment from 'moment/moment.js';


class UniqueProductionTask extends React.Component{
    constructor(props){
        super(props);

        this.state = { 
            appid: "",
            production_tasks : [],
        }
    }

    componentWillReceiveProps(){
		if(this.props.data.length > 3){
			this.loadAppStatus(this.props.data);
		}
    };
    
    loadAppStatus(appid) {

		let myColor = {
			background: '#2196F3',
			text: "#FFFFFF"
		};
		this.setState({
			appid: appid
		});
        notify.show("Loading ....", "custom", 1500, myColor)
        this.loadTaskArray();
    }

    loadTaskArray(){
        const self = this;
        firebase.database().ref('/tasks/production/').once('value').then(function(snapshot){
            var task_array = [];
            if(snapshot.val()){
                var all_production_tasks = snapshot.val();
                for(var i in all_production_tasks){
                    if(all_production_tasks[i].appid === self.state.appid){
                        all_production_tasks[i].date = moment.unix(all_production_tasks[i].created_at).format('MMM Do YY')
                        task_array.push(all_production_tasks[i]);
                    }
                }
            }
            task_array = self.sortArrayByCreatedAt(task_array);
            self.setState({
                production_tasks : task_array
            });
        });
    }

    sortArrayByCreatedAt(task_array){
        for(var i=0; i<task_array.length; i++){
            for( var j=0; j<task_array.length; j++){
                if(task_array[i].created_at > task_array[j].created_at){
                    var temp_json = task_array[i];
                    task_array[i] = task_array[j];
                    task_array[j] = temp_json 
                }
            }
        }
        return task_array;
    }

    render(){
        return(
            <div style ={{"overflow":"hidden"}}><UTaskCard production_tasks = {this.state.production_tasks} /></div>
        )
    }
}

export default UniqueProductionTask;