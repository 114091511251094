import React from 'react';
import * as firebase from 'firebase';
import "./createKRForm.css";

import SketchColorPicker from './colorPicker.jsx';
import { notify } from 'react-notify-toast';
import axios from 'axios';


const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});


class KRForm extends React.Component{
    constructor(props){
        super(props);

        this.handlePrimaryColorChange = this.handlePrimaryColorChange.bind(this);
        this.handlePrimaryDarkColorChange = this.handlePrimaryDarkColorChange.bind(this);
        this.handleAccentColorChange = this.handleAccentColorChange.bind(this);
        this.handleSplashColorChange = this.handleSplashColorChange.bind(this);
        this.handleSplashSpinnerColorChange = this.handleSplashSpinnerColorChange.bind(this);
        this.submitKRForm = this.submitKRForm.bind(this);

        this.state = {
            name : "",
            store_name : "",
            store_url : "",
            kr_api_key : "",
            phone_number : "",
            email : "", 
            password : "",
            package_name : "",
            primary_color : "#121212",
            primary_dark_color : "#121212",
            accentColor : "#CA411F",
            splashColor : "#ffffff",
            splashSpinnerColor : "#121212"
        };
    }

    submitKRForm(){
        const self = this;
        if(!self.state.name || !self.state.store_name || !self.state.store_url || !self.state.kr_api_key || !self.state.phone_number || !self.state.email || !self.state.package_name || !self.state.password){
            notify.show("Invalid value","error",2000);
            return;
        }

        axios.post('https://vajro.herokuapp.com/newKRUser',{
            "name": this.state.name.trim(),
            "storeName": this.state.store_name.trim(),
            "storeUrl": this.state.store_url.trim(),
            "krApiKey": this.state.kr_api_key.trim(),
            "phoneNumber": this.state.phone_number.trim(),
            "email": this.state.email.trim(),
            "password": this.state.password.trim(),
            "primaryColor": this.state.primary_color.trim(),
            "primaryDarkColor": this.state.primary_dark_color.trim(),
            "accentColor": this.state.accentColor.trim(),
            "splashColor": this.state.splashColor.trim(),
            "splashSpinnerColor": this.state.splashSpinnerColor.trim(),
            "packageName": this.state.package_name.trim()
        }).then(function (response) {
            if(response.data){
                if(response.data.status === "failure"){
                    notify.show("Error in creating KR user","error",2000);
                    return;
                } else if(response.data.status === "success"){
                    notify.show("KR user Successfully created","success",2000);
                    return;
                }
            } else{
                notify.show("Error in creating KR user","error",2000);
                return;
            }
        }).catch(function (error) {
            console.log(error);
            notify.show("Error in creating KR user","error",2000);
            return;
        });
        
    }


    handlePrimaryColorChange(color){
        this.setState({ 
            primary_color : color.hex
        });
    }

    handlePrimaryDarkColorChange(color){
        this.setState({
            primary_dark_color : color.hex
        });
    }

    handleAccentColorChange(color){
        this.setState({
            accentColor : color.hex
        });
    }

    handleSplashColorChange(color){
        this.setState({
            splashColor : color.hex
        });
    }

    handleSplashSpinnerColorChange(color){
        this.setState({
            splashSpinnerColor : color.hex
        });
    }


    render(){
        return(
            <div className = "form_container">
                <div>
                    <label className = "col-md-3">Name : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.name}  onChange={event => this.setState(byPropKey('name', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Store Name : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.store_name} onChange={event => this.setState(byPropKey('store_name', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Store URL : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.store_url} onChange={event => this.setState(byPropKey('store_url', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">KR API Key : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.kr_api_key} onChange={event => this.setState(byPropKey('kr_api_key', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Phone Number : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.phone_number} onChange={event => this.setState(byPropKey('phone_number', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Email : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.email} onChange={event => this.setState(byPropKey('email', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Password : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.password} onChange={event => this.setState(byPropKey('password', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Package Name : </label>
                    <input className="formTextBox row" type = "text" value = {this.state.package_name} onChange={event => this.setState(byPropKey('package_name', event.target.value))} /> 
                </div>
                <div>
                    <label className = "col-md-3">Primary color : </label>
                    <SketchColorPicker default_color = {this.state.primary_color} onChangeColor = {this.handlePrimaryColorChange} />
                </div>
                <div >
                    <label className = "col-md-3">Primary Dark color : </label>
                    <SketchColorPicker default_color = {this.state.primary_dark_color} onChangeColor = {this.handlePrimaryDarkColorChange} />
                </div>
                <div>
                    <label className = "col-md-3">Accent color : </label>
                    <SketchColorPicker default_color = {this.state.accentColor} onChangeColor = {this.handleAccentColorChange} />
                </div>
                <div>
                    <label className = "col-md-3">Splash color : </label>
                    <SketchColorPicker default_color = {this.state.splashColor} onChangeColor = {this.handleSplashColorChange} />
                </div>
                <div>
                    <label className = "col-md-3">Splash Spinner color : </label>
                    <SketchColorPicker default_color = {this.state.splashSpinnerColor} onChangeColor = {this.handleSplashSpinnerColorChange} />
                </div>
                <div>
                    <button className = "krsubmitbutton" onClick = {this.submitKRForm}>Create KR User</button>
                </div>
            </div>
        );
    }
}

export default KRForm;