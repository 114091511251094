import React from 'react';
import * as firebase from 'firebase';

import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import './createKRForm.css';
var hexToRgba   = require('hex-and-rgba').hexToRgba;

class SketchColorPicker extends React.Component {
	constructor(props){
		super(props);
	
		this.handleCallback = this.handleCallback.bind(this);

		this.state = {
			displayColorPicker: false,
			color: {
				// r: '241',
				// g: '112',
				// b: '19',
				// a: '1',
			},
		};
	}
	
	componentWillMount(){
        if(this.props.default_color){
			var temp_color = {};
			var rgba = hexToRgba(this.props.default_color);
			temp_color.r = rgba[0];
			temp_color.g = rgba[1];
			temp_color.b = rgba[2];
			temp_color.a = rgba [3]
            if(JSON.stringify (temp_color) !== JSON.stringify(this.state.color)){
                this.setState({
                        color : temp_color
                });
            } 
		}
	}

  
    handleClick = () => {
    	this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
  
    handleClose = () => {
    	this.setState({ displayColorPicker: false })
    };
  
    handleChange = (color) => {
		var rgba = hexToRgba(color.hex);
		
		this.setState({ color: color.rgb });
		this.handleCallback(color);
	};
	
	handleCallback(color){
		this.props.onChangeColor(color)
	}
  
    render() {
  
		const styles = reactCSS({
			'default': {
				color: {
					width: '36px',
					height: '14px',
					borderRadius: '2px',
					background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
				},
				swatch: {
					padding: '5px',
					background: '#fff',
					borderRadius: '1px',
					boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
					display: 'inline-block',
					cursor: 'pointer',
				},
				popover: {
					position: 'absolute',
					zIndex: '2',
				},
				cover: {
					position: 'fixed',
					top: '0px',
					right: '0px',
					bottom: '0px',
					left: '0px',
				},
			},
		});
  
		return (
			<div>
				<div className = "colorPickerContainer" style={ styles.swatch } onClick={ this.handleClick }>
					<div style={ styles.color } />
				</div>
				{ this.state.displayColorPicker ? <div style={ styles.popover }>
				<div style={ styles.cover } onClick={ this.handleClose }/>
				<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
				</div> : null }
			</div>
		);
	}
}
  
  export default SketchColorPicker;