import React from 'react';
import * as firebase from 'firebase';
import './task.css'
import { Dropdown,MenuItem,Glyphicon,DropdownButton,ButtonToolbar } from 'react-bootstrap';
import { Button } from 'mdbreact';
import { notify } from 'react-notify-toast';
import { func } from 'prop-types';


const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
  });
class CreateTaskButton extends React.Component{
    constructor(props){
        super(props);

        this.showForm = this.showForm.bind(this);
        this.createTaskInDb = this.createTaskInDb.bind(this);
        this.handleNewTaskCreated = this.handleNewTaskCreated.bind(this);

        this.state = {
            appid : "",
            platform : "android",
            reason : "",
            status : "pending",
            comments : [],
            show_task_form : "hide",
            app_name:"",
            intercom: ""

        }
    }
   
    handleNewTaskCreated(){
        this.props.onTaskCreated("updated");
    }

    showForm(){
        if(this.state.show_task_form === "hide"){
            this.setState({
                show_task_form : "show"
            });
        } else {
            this.setState({
                show_task_form : "hide"
            });
        }
    }

    handleCommentbox(event){
        // console.log(event);
        var comment_array = [];
        comment_array.push(event.target.value);
        this.setState({
            "comments" : comment_array
        });
    }

    handleStatusChange(status_index){
        var current_status = "";

        if(status_index == 1){
            current_status = "onhold";
        } else if(status_index == 2){
            current_status = "live";
        } else if(status_index == 3){
            current_status = "pending";
        } else if(status_index == 4){
            current_status = "submitted";
        } else if(status_index == 5){
            current_status = "uploaded";
        }

        this.setState({
            "status" : current_status
        });
    }

    handlePlatformChange(platform_index){
        var current_platform = "android";

        if(platform_index == 2){
            current_platform = "ios";
        } else if(platform_index == 3){
            current_platform = "both";
        }

        this.setState({
            "platform":current_platform
        });
    }

    createTaskInDb(){

        if(this.state.appid.trim().length === 0){
            notify.show("Required App Id", "error", 3000);
            return;
        } else if(this.state.reason.trim().length === 0){
            notify.show("Required Reason", "error", 3000);
            return;
        } else if(this.state.app_name.trim().length === 0){
            notify.show("Required App Name", "error", 3000);
            return;
        }

        var platform_array = [];
        if(this.state.platform === "android"){
            platform_array.push("android");
        } else if(this.state.platform === "ios"){
            platform_array.push("ios");
        } else{
            platform_array.push("ios");
            platform_array.push("android");
        } 

        if(this.state.appid.trim() && this.state.reason.trim() && this.state.app_name.trim()){
            for(var i=0; i<platform_array.length;i++){
                var random_string = Math.random().toString(36).replace('0.', ''); 
                const dateTime = Date.now();
                const timestamp = Math.floor(dateTime / 1000);
                var task_id = random_string + timestamp;
                var final_json = {};
                final_json.appid = this.state.appid.trim();
                final_json.reason = this.state.reason.trim();
                final_json.created_at = timestamp;
                final_json.completed_at = "";
                final_json.updated_at = timestamp;
                final_json.id = task_id;
                final_json.comments = this.state.comments;
                final_json.status = this.state.status;
                final_json.platform = platform_array[i];
                final_json.app_name = this.state.app_name.trim();
                final_json.intercom = this.state.intercom;

                this.showForm();
                let db = firebase.database().ref('/tasks/production/'+task_id);
                db.set(final_json).then(function(){
                    notify.show("Task Created SuccessFully", "success", 2000);
                });
                
                window.heap.track(final_json.status, {"reason": final_json.reason, "appid": final_json.appid,"platform":final_json.platform,"task_id":final_json.id,"app_name":final_json.app_name});
                this.handleNewTaskCreated();
                this.initialTheState();//this is called to clear the value in the input form
            }
            
        } else{
            notify.show("Necessary Field Missing", "error", 2000);
            return;
        }

    }

    initialTheState(){
        this.setState({
            appid : "",
            platform : "android",
            reason : "",
            status : "pending",
            comments : [],
            app_name : ""
        });
    }

    render(){
        return(
            <div className = "create_task_container">
                <button className = "create_task_icon" id = "show" onClick = {this.showForm}>Create Task  <i className="fas fa-plus-circle"></i></button>
                <div className = {this.state.show_task_form} id = "task_form" >
                    <div>
                        <label className = "col-md-3">App ID : </label>
                        <input className="taskTextBox row" type = "text" value = {this.state.appid}  onChange={event => this.setState(byPropKey('appid', event.target.value))} /> 
                    </div>
                    <div>
                        <label className = "col-md-3">App Name : </label>
                        <input className="taskTextBox row" type = "text" value = {this.state.app_name} onChange={event => this.setState(byPropKey('app_name', event.target.value))} /> 
                    </div>
                    <div >
                        <label className = "col-md-3">Reason : </label>
                        <input className="taskTextBox row" type = "text" value = {this.state.reason} onChange={event => this.setState(byPropKey('reason', event.target.value))} /> 
                    </div>
                    <div >
                        <label className = "col-md-3">Intercom Chat Link : </label>
                        <input className="taskTextBox row" type = "text" value = {this.state.intercom} onChange={event => this.setState(byPropKey('intercom', event.target.value))} /> 
                    </div>
                    <div>
                        <label className = "col-md-3">Platform : </label>
                        <div className = "react_dropdown"> 
                            <ButtonToolbar>
                                <Dropdown id="dropdown-custom-1" >
                                <Dropdown.Toggle>
                                    {this.state.platform}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors" onSelect = {e =>this.handlePlatformChange(e)}>
                                <MenuItem eventKey="1" >Android</MenuItem>
                                <MenuItem eventKey="2">iOS</MenuItem>
                                <MenuItem eventKey="3">Both</MenuItem>
                                <MenuItem divider />
                                </Dropdown.Menu>
                                </Dropdown>
                            </ButtonToolbar>
                        </div>

                    </div>
                    {/* <div>
                        <label className = "col-md-3">Comments : </label>
                        <input className="taskTextBox row" type = "text" value = {this.state.comments} onChange={event => this.handleCommentbox.bind(this)} /> 
                    </div> */}
                    <div className = "divspacing">
                        <label className = "col-md-3">Status : </label>
                        <div className = "react_dropdown"> 
                            <ButtonToolbar>
                                <Dropdown id="dropdown-custom-1" >
                                <Dropdown.Toggle>
                                    {this.state.status}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors" onSelect = {e =>this.handleStatusChange(e)}>
                                <MenuItem eventKey="3">Pending</MenuItem>
                                <MenuItem eventKey="5">Uploaded</MenuItem>
                                <MenuItem eventKey="4">Submitted</MenuItem>
                                <MenuItem eventKey="2">Live</MenuItem>
                                <MenuItem eventKey="1" >Onhold</MenuItem>
                                <MenuItem divider />
                                </Dropdown.Menu>
                                </Dropdown>
                            </ButtonToolbar>
                        </div>         
                    </div>
                    <span className = "task_button_alignment divspacing">
                        <button id ="taskFormButton" onClick = {this.createTaskInDb}>Save</button>
                        <button  onClick = {this.showForm} id = "taskFormButton">Cancel</button>
                    </span>
                </div>
            </div>
            
        );
    }
}

export default CreateTaskButton;