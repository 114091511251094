import React from 'react';
import * as firebase from 'firebase';
import './facebook.css';
/*global FB*/

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

class Facebook_post_message extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            postMessage : "",
        };

        this.facebookPostMessage = this.facebookPostMessage.bind(this);
        this.facebookClearPostMessage = this.facebookClearPostMessage.bind(this);
    }
    
    facebookPostMessage(){
        const self = this;
        
        var token = "";
        var facebookPageId = "";
        var finalPostUrl = "";

        FB.getLoginStatus(function(response) {
            if (response.status === 'connected') {

                // FB.api('/me/permissions', function(response) {
                //     console.log("Permission");
                //     console.log(JSON.stringify(response));       
                // }); 

                FB.api('/me/accounts', function(response) { 
                    
                    token = response.data[0].access_token;
                    facebookPageId = response.data[0].id;
                    finalPostUrl = '/' + facebookPageId + '/feed';
                    FB.api(
                        finalPostUrl,
                        'POST',
                        {
                            "message": self.state.postMessage,
                            "access_token": token
                        },
                        function (response) {
                            if (response && !response.error) {
                                console.log(JSON.stringify(response)); 
                                self.setState({
                                    postMessage:""
                                })      
                            } else {
                                console.log("Error in Posting in facebook");
                                console.log(JSON.stringify(response.error));       
                            }
                        }
                    );
                    
                }); 
            }
          });

         
    }

    facebookClearPostMessage(){
        this.setState({
            postMessage:""
        });
    }

    render(){
        return(
            <div className = "FbPostMessageContainer">
                <label className = "col-md-3">Fb Post Message : </label>
                <textarea  className="post_textbox row" cols={100} rows={5} value = {this.state.postMessage} onChange={event => this.setState(byPropKey('postMessage', event.target.value))} />
                <button className = "facebookPostButton row" onClick= {this.facebookPostMessage} >Post in Facebook</button>
                <button className = "facebookPostButton row" onClick= {this.facebookClearPostMessage} >Clear</button>
            </div>

        )
    }
}

export default Facebook_post_message;